import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setStateMany } from '../../redux/checkout';
import { useDispatch } from 'react-redux';

import getAddressesFromPostcode from '../../helpers/postcodeFetch';
import InputText from './inputText';

export default function AddressLookup() {
  const { Customer } = useSelector((state) => state.checkout)
  const [PostCode, setPostcode] = useState('');
  const [addresses, setAddresses] = useState(null);
  const dispatch = useDispatch();

  function handleChange(e) {
    if (e.target.value === "Please Select...") {
      return;
    }

    const selectedAddress = addresses.addresses[e.target.value];
    const [AddressL1, AddressL2, AddressL3, City, CountyRegionState] = selectedAddress.formatted_address;

    dispatch(setStateMany({
      root: "Customer",
      updates: [
        { key: "AddressL1", value: AddressL1 },
        { key: "AddressL2", value: AddressL2 },
        { key: "AddressL3", value: AddressL3 },
        { key: "City", value: City },
        { key: "CountyRegionState", value: CountyRegionState },
        { key: "PostCode", value: PostCode }
      ]
    }));
  }

  return (
    <>
      <div className='editCustomerPostCodeSearchContainer'>
        <label>
          PostCode Lookup:
        </label>

        <div className='editCustomerPostCodeSearchControls'>
          <input type="text" name="PostCode" value={PostCode || ''} onChange={(e) => {
            const toUpper = e.target.value.toUpperCase();
            const validValue = toUpper.replace(/[^ A-Z0-9]/g, ''); // Remove forbidden characters
            setPostcode(validValue);
          }} />
          <button className="button button--primary" onClick={() => {
            getAddressesFromPostcode(PostCode.replaceAll(/[ ]/g, '')).then(data => setAddresses(data));
          }}>Search</button>
        </div>

        <div className="editCustomerPostCodeSearchResults">
          {addresses ?
            <select onChange={(e) => handleChange(e)}>
              <option>Please Select...</option>
              {addresses.addresses.map((address, index) => {
                return (
                  <option key={index} value={index}>{address.line_1}</option>
                )
              })}
            </select>
            : null}
        </div>
      </div>

      <InputText
        label={"Address Line One"}
        identifier={"addressL1"}
        dispatchRoot={"Customer"}
        dispatchKey={"AddressL1"}
        initialValue={Customer.AddressL1} />
      <InputText label={"Address Line Two"} identifier={"addressL2"} dispatchRoot={"Customer"} dispatchKey={"AddressL2"} required={false} initialValue={Customer.AddressL2} />
      <InputText label={"Address Line Three"} identifier={"addressL3"} dispatchRoot={"Customer"} dispatchKey={"AddressL3"} required={false} initialValue={Customer.AddressL3} />
      <InputText label={"City"} identifier={"city"} dispatchRoot={"Customer"} dispatchKey={"City"} initialValue={Customer.City} />
      <InputText label={"County"} identifier={"County"} dispatchRoot={"Customer"} dispatchKey={"CountyRegionState"} initialValue={Customer.CountyRegionState} />
      <InputText label={"Post Code"} identifier={"postCode"} dispatchRoot={"Customer"} dispatchKey={"PostCode"} initialValue={Customer.PostCode} />
    </>
  )
}