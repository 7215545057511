import CheckoutRow from "./checkout/row";
import InputDropdown from "./checkout/inputDropdown";
import InputDate from "./checkout/inputDate";
import { globals } from "../helpers/misc";
import { useSelector } from "react-redux";

export default function CartProduct({
    itemId
}) {
    const { Cart, PrePurchaseResponse } = useSelector(state => state.checkout);
    const productVariantMap = new Map([["Please Select", "Please Select"]]);

    // const productVariantCount = Object.keys(Cart).reduce((previous, current) => {
    //     const matchingPprProductCount = PrePurchaseResponse.Products.filter(pprProduct => pprProduct.ProductId === itemId).length;
    //     previous[current] = matchingPprProductCount;
    //     return previous;
    // }, {});

    const matchingPprProducts = PrePurchaseResponse.Products.filter(pprProduct => pprProduct.ProductId === itemId);
    matchingPprProducts.forEach(product => {
        if (!productVariantMap.has(product.RateId, product.ProductName)) {
            productVariantMap.set(product.RateId, product.ProductName)
        }
    });

    const firstMatchingPprProduct = PrePurchaseResponse.Products.find(({ ProductId }) => ProductId === itemId);
    const showDirectDebitWarning = firstMatchingPprProduct.DirectDebitPeriod === 0;
    const showVariantSelector = productVariantMap.size > 2; // Only true if more than 1 variant (as Please Select is in the map by default)
    const cartItem = Cart[itemId];

    return (
        <>
            <div style={{ marginLeft: "1.5rem" }}>
                {
                    showVariantSelector &&
                    <InputDropdown customMap={productVariantMap}
                        label={"Select variant:"}
                        identifier={"productVariantSelector"}
                        dispatchRoot={"VariantSelection"}
                        dispatchKey={itemId}
                    />
                }
                <CheckoutRow label={"Provider"} value={firstMatchingPprProduct.Provider} />
                <CheckoutRow label={"Policy"} value={firstMatchingPprProduct.ProductName} />
                <CheckoutRow label={"Term"} value={firstMatchingPprProduct.Term} />

                {
                    showDirectDebitWarning &&
                    <div style={{ marginTop: "0.5rem" }}>
                        <i>Note: this product cannot be purchased with Direct Debit payments.</i>
                    </div>
                }

                <div style={{ marginTop: "1rem" }} className='product--startDate'>
                    <InputDate
                        label={"Policy Start Date"}
                        identifer={"policyStartDate"}
                        dispatchRoot={"Cart"}
                        dispatchKey={"StartDate"}
                        additionalClasses={["editPolicyStartDate"]}
                        initialValue={cartItem.StartDate}
                        purchaseId={cartItem.id}
                        min={globals.today}
                    />
                </div>
            </div>
        </>
    )
}