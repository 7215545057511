import Headbar from "../components/headbar";

export default function ErrorPage({ message = null, displayMessage = true, headbar = true }) {
    return (
        <>
            {headbar && <Headbar back={"/"} backText={"Main Menu"} />}
            <div className="checkout--container" style={{ display: "flex", alignItems: "center" }}>
                <span>There was an error processing your request.</span>
                <span>If this issue persists, please contact Automotive Compliance.</span>
                <span style={{ fontWeight: "bold" }}>{displayMessage && message}</span>
            </div>
        </>
    )
}