import { useState } from 'react';
import { useStripe, useElements, AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { globals } from '../../helpers/misc';

export default function CheckoutForm() {
  //const { uri } = useSelector((state) => state.authorise)
  const { Forename, Surname, AddressL1, AddressL2, City, County, PostCode } = useSelector((state) => state.checkout.Customer)
  const [errorMessage, setErrorMessage] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const [defaultAddress] = useState({
    mode: 'billing',
    defaultValues: {
      name: `${Forename} ${Surname}`,
      address: {
        line1: AddressL1,
        line2: AddressL2,
        city: City,
        state: County,
        postal_code: PostCode,
        country: 'GB',
      },
    },
  });

  //const uri = "http://localhost:3000/payment/response"
  // This path will be incorrect if using BrowserRouter
  const uri = `${window.location.href}/payment/response`;

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: { return_url: uri },
      redirect: "if_required"
    });

    if (error) {
      // Show error to your customer (for example, payment details incomplete)
      setErrorMessage(error.message);
    } else if (paymentIntent) {
      // const payload = `?payment_intent=${paymentIntent.id}&payment_intent_client_secret=${paymentIntent.client_secret}&redirect_status=succeeded`
      navigate(`./response?redirect_status=succeeded`);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // Cards do not redirect, it is used for things such as PayPay, Klarna etc
      // See https://docs.stripe.com/payments/payment-methods/integration-options#additional-api-supportability
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <AddressElement options={defaultAddress} />
        <PaymentElement />
        <button className={"button button--primary"} style={{ width: "100%", marginTop: "25px" }} disabled={!stripe}>Submit</button>
      </form>
      {errorMessage &&
        <div className='checkout--container'>
          <h1>There was an error completing the payment:</h1>
          <h1><i>{errorMessage}</i></h1>
        </div>
      }
    </>
  )
};