// Content component for rendering content blocks
// Props:
//  - title: string
//  - content: string
//  - multipleColumns: boolean
//  - children: any

export default function Content(props) {
  let columnCount;
  let columnClass;

  if (props.multipleColumns) {
    const pTagCount = props.content.match(/<p>/g);
    columnCount = pTagCount ? pTagCount.length : 0;
  }

  if (columnCount === 2)
    columnClass = "paragraph--col-2";
  if (columnCount === 3)
    columnClass = "paragraph--col-3";

  return (
    <>
      <div className='flow--12'>
        {props.title ? <h3 className='h4 semi'>{props.title}</h3> : null}
      </div>
      {props.content ? <div className={props.multipleColumns ? `paragraph ${columnClass ? columnClass : ''} flow--16` : 'paragraph flow--16'} dangerouslySetInnerHTML={{ __html: props.content }}></div> : props.children}
    </>
  );
}
