import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useStripe( amt, curr = 'gbp' ) {
  const [ client, setClient ] = useState()
  const [ params, setParams ] = useState()
  const { license } = useSelector((state) => state.authorise) // Rretrieve the license key from our session Redux store

  useEffect(() => {
    if( license ) {
      // Retrieve the keys from the dealers JSON file
      async function getKeys() {
        const response = await fetch(process.env.REACT_APP_API_URL + "/gateway/index.php?"
            + ( license ? `license=${license}` : '' ), 
          { method: "GET" })
            .catch(err => console.log(err))

        const json = await response.json()
        return json
      }

      const gatewayKeys = getKeys(); // When the key is returned, we can then create a Stripe instance

      if(gatewayKeys) {
        gatewayKeys.then((keys) => {
          setClient(keys.gateway_client)

          let headersList = {
            "content-type": "application/x-www-form-urlencoded",
            "Authorization": `Bearer ${keys.gateway_secret}`
          }

          // Create Stripe instance with amount and currency passed in
          fetch(`https://api.stripe.com/v1/payment_intents?amount=${amt}&currency=${curr}`, { method: "POST", headers: headersList })
            .then(response => response.json())
            .then(data => setParams(data))
            .catch(err => console.log(err))
        })
      }
    }    
  }, [license, amt, curr, setClient, setParams])

  return { client, params }
}