import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Required from "../required";
import { setState, setStateMany } from "../../redux/checkout";
import "../../styles/components/inputs.scss";
import { globals } from "../../helpers/misc";
import { resetDirectDebitDetails, resetPremiumFundingDetails } from "../../helpers/resetStates";

// Use Map objects to guarantee item order.

const financeBaseOptions = new Map([
    ["Please Select", "Please Select"],
    ["Cash", "Cash"], // Temporarily disabled
    ["DirectDebit", "Direct Debit"],
    ["PremiumFunding", "Premium Funding"] // Temporarily disabled]
]);

// vehicleFinanceTypeOptions is better?
const financeTermOptions = new Map([
    ["Please Select", "Please Select"],
    ["BA", "Balanced Payments / Variable Rate"],
    ["CH", "Business Contract Hire"],
    ["CP", "Business Contract Purchase"],
    ["CSA", "Credit Sale Agreement"],
    ["FL", "Finance Lease"],
    ["HP", "Hire Purchase"],
    ["LP", "Lease Purchase"],
    ["PL", "Motor Personal Loan"],
    ["PCH", "Personal Contract Hire"],
    ["PCP", "Personal Contract Purchase"],
    ["PCHPL", "Personal Contract Hide Personal Loan"],
    ["PCPPL", "Personal Contract Purchase Personal Loan"]
]);

const instalmentOptions = new Map([
    ["Please Select", "Please Select"],
    [6, "6 Months"],
    [12, "12 months"],
    [18, "18 months"],
    [24, "24 months"],
    [30, "30 months"],
    [36, "36 months"],
    [42, "42 months"],
    [48, "48 months"],
    [54, "54 months"],
    [60, "60 months"]
]);

const titleOptions = new Map([
    ["Please Select", "Please Select"],
    ["Mr", 'Mr'],
    ["Ms", 'Ms'],
    ["Miss", 'Miss'],
    ["Mrs", 'Mrs'],
    ["Dr", 'Dr'],
    ["Sir", 'Sir'],
    ["Rev", 'Rev']
]);

const purchaseTypeOptions = new Map([
    ["Please Select", "Please Select"],
    ["Private", "Private"],
    ["Company", "Company"]
]);

const vehicleFinanceTypeOptions = new Map([
    ["Please Select", "Please Select"],
    ["HP", "Hire Purchase"],
    ["CP", "Business Contract Purchase"],
    ["LP", "Lease Purchase"],
    ["CH", "Business Contract Hire"],
    ["PCH", "Personal Contract Hire"],
    ["PCP", "Personal Contract Purchase"],
    ["FL", "Finance Lease"],
    ["PL", "Motor Personal Loan"],
    ["BA", "Balanced Payments / Variable Rate"],
    ["CSA", "Credit Sale Agreement"],
    ["COND", "Conditional Sale"],
    ["PCHPL", "PCH Personal Loan"],
    ["PCPPL", "PCP Personal Loan"],
    ["APP", "Advanced Payment Plan"],
    ["HPPL", "HP Personal Loan"]
]);

export default function InputDropdown(
    {
        label,
        identifier,
        required = true,
        dispatchRoot = null,
        dispatchKey = null,
        classNameSuffix = "",
        initialValue = null,
        customMap = null,
    }) {
    const [optionsToDisplay, setOptionsToDisplay] = useState({});
    const [selected, setSelected] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const htmlId = `InputText${dispatchRoot ? dispatchRoot : ''}${dispatchKey ? dispatchKey : ''}`;
    const htmlClassName = `input--dropdown${classNameSuffix ? classNameSuffix : ''}`;

    const dispatchValue = value => {
        identifier === "instalments" && (value = Number(value));

        globals.debug && dispatchKey && console.log(`${dispatchRoot}: ${dispatchKey} dispatching ${typeof value}: ${value}`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value }));
    };

    const dispatchNull = () => {
        globals.debug && dispatchKey && console.log(`${dispatchRoot}: ${dispatchKey} dispatching null`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: null }));
    };

    useEffect(() => {
        customMap !== null && setOptionsToDisplay(customMap)

        identifier === "fundingSource" && setOptionsToDisplay(financeBaseOptions);
        identifier === "instalments" && setOptionsToDisplay(instalmentOptions);
        identifier === "private/Company" && setOptionsToDisplay(purchaseTypeOptions);
        identifier === "title" && setOptionsToDisplay(titleOptions);
        identifier === "financeType" && setOptionsToDisplay(vehicleFinanceTypeOptions);
        identifier === "vehicleFinanceType" && setOptionsToDisplay(vehicleFinanceTypeOptions);
    }, [identifier]);

    useEffect(() => {
        initialValue && setSelected(initialValue);
        initialValue ? setError("") : setError(required ? "Required" : "");
    }, [initialValue]);

    const resetFinanceData = type => {
        // Clear the redux store of data for the options that are not selected
        type === "directDebit" && resetDirectDebitDetails(dispatch);
        type === "premiumFunding" && resetPremiumFundingDetails(dispatch);
    };

    const handleChange = input => {
        if (!input)
            return;

        if (input === "Please Select") {
            setError("Required");
            dispatchNull();
            setSelected(input);
            return;
        }

        if (input === "Cash")
            dispatch(setState({ root: "FinanceOptions", key: "PurchaseType", value: "CASH" }));

        if (input === "DirectDebit") {
            resetFinanceData("premiumFunding");
            dispatch(setState({ root: "FinanceOptions", key: "PurchaseType", value: "CASH" }));
        }

        if (input === "PremiumFunding") {
            resetFinanceData("directDebit")
            dispatch(setState({ root: "FinanceOptions", key: "PurchaseType", value: "FINANCE" }));
        }

        dispatchValue(input);
        setSelected(input);
        setError("");
    };

    return (
        <div className={htmlClassName}>
            <label htmlFor={htmlId}>
                {label}
                {/* {required && <Required />} */}
            </label>

            <select
                id={htmlId}
                value={selected}
                onChange={e => handleChange(e.target.value)}>
                {optionsToDisplay instanceof Map && [...optionsToDisplay.entries()].map(([value, label]) => {
                    return (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    )
                })}
            </select>
            <span className={`input--dropdownError ${error ? 'visible' : ''}`}>{error || "✅"}</span>
        </div>
    )
}