import { useState, useEffect } from "react";

const fetchParams = {
  method: "GET",
  mode: 'cors',
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  }
}

export default function useLicense(key) {
  const [data, setData] = useState()

  useEffect(() => {
    if (key && !data) {
      fetch(process.env.REACT_APP_API_URL + "/sites/index.php?"
        + (key ? `license=${key}` : ''),
        fetchParams)
        .then(response => response.json())
        .then(data => setData(data))
        .catch(err => console.log(err))
    }
  }, [key, data])

  return data;
}