import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Required from "../required";
import { setState } from "../../redux/checkout";
import "../../styles/components/inputs.scss"
import { globals } from "../../helpers/misc";

export default function InputNumber(
    {
        label,
        identifier,
        required = true,
        initialValue = null,
        dispatchRoot = null,
        dispatchKey = null,
        classNameSuffix = null,
        min = null,
        max = null
    }) {

    const [value, setValue] = useState("");
    const [error, setError] = useState("")
    const dispatch = useDispatch();
    const htmlId = `InputText${dispatchRoot ? dispatchRoot : ''}${dispatchKey ? dispatchKey : ''}`
    const htmlClassName = `input--number${classNameSuffix ? classNameSuffix : ''}`;

    useEffect(() => {
        initialValue && handleChange(initialValue);
        !value && setError("Required");
    }, [initialValue]);

    const dispatchValue = inputValue => {
        inputValue = Number(inputValue);

        globals.debug && dispatchKey && console.log(`${dispatchKey} dispatching ${typeof inputValue}: ${inputValue}`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: inputValue }));
    }
    const dispatchNull = () => {
        globals.debug && dispatchKey && console.log(`${dispatchKey} dispatching null`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: null }));
    }

    const handleAccountNumber = input => {
        if (!input) {
            setError("Required");
            setValue("");
            return;
        }

        // Neccesary to convert to string as it is dispatched to the store as a Number
        input = String(input).replace(/\D/g, '');

        if (input.length > 8)
            return;

        if (input.length < 6) {
            // If below 6 chars, dispatch null
            dispatchNull();
            setError("Minimum six digits.")
            setValue(input);
            return;
        }

        if (input.length >= 6) {
            // Only dispatch to store once min 6 chars
            dispatchValue(input)
            setError("");
            setValue(input);
        }
    }

    const handleMileagePerAnnum = input => {
        if (!input) {
            setError("Required");
            setValue("");
            return;
        }

        input = String(input).replace(/[^0-9]/, '');

        if (Number(input) > max) {
            setError(`Maximum of ${max} miles.`);
            return;
        }

        if (!input) {
            dispatchNull();
            setError("Required");
            setValue("");
            return;
        }

        if (min && Number(input) < min) {
            // Allow entry of inputs < min, because in the process of typing a large number you will type a small one
            dispatchNull();
            setError(`Minimum value must be ${min}.`);
            setValue(input);
            return;
        }
        if (max && Number(input) > max) {
            input = max.toString();
            dispatchValue(input);
        }

        dispatchValue(input);
        setValue(input);
        setError("");
    }

    const handleChange = input => {
        identifier === "accountNumber" && handleAccountNumber(input);
        identifier === "mileagePerAnnum" && handleMileagePerAnnum(input);
    }

    return (
        <div className={htmlClassName}>
            <label htmlFor={htmlId}>
                {label}
                {/* {required && <Required />} */}
            </label>

            <input
                type="text"
                value={value}
                id={htmlId}
                onChange={(e) => handleChange(e.target.value)}
                min={min && min}
                max={max && max}
            >
            </input>
            <span className={`input--numberError ${error ? 'visible' : ''}`}>{error || "✅"}</span>
        </div>
    )
}