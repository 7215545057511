import { useDispatch, useSelector } from "react-redux";
import { addProduct } from "../redux/checkout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Loading from "./loading";
import { getProductItemAsync } from "../helpers/apiRequestHelper";
import ErrorPage from "../pages/error";

export default function Slug() {
    const { id } = useParams();
    const { license } = useSelector(state => state.authorise);
    const [Data, setData] = useState(null);
    const [error, setError] = useState(null);

    const [params] = useState(new URLSearchParams(useLocation().search));
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (license && !Data) {
            getProductItemAsync(license, id).then(response => setData(response))
                .catch(error => setError(error.message))
        }

        if (Data) {
            const queryStringId = params.get("id");
            const termPricing = Data.Pricing.find(term => term.Id === queryStringId);

            if (!termPricing || !queryStringId) {
                setError("One or more validation errors occurred.")
                return;
            }

            if (queryStringId && termPricing) {
                dispatch(addProduct({
                    id,
                    name: Data.Name,
                    cost: termPricing.Price,
                    pricingId: termPricing.Id,
                    // not setting eligibility as assume if email received customer must be eligible
                    checks: true
                }));

                navigate("/checkout");
            }
        }
    }, [license, Data, dispatch, navigate]);


    if (error)
        return <ErrorPage message={error || "Unauthorised."} />
    else
        return <Loading />
}