import { globals } from "./misc";
const cache = new Map();
// Functions per API request. Allows more freedom around making API calls as opposed to using a custom hook.

function buildFetchOptions(endpoint, method, license, payload = null) {
    let url;
    const options = {
        method: method
    };

    if (method === "GET") {
        url = `${process.env.REACT_APP_API_URL}/connect/index.php?endpoint=${endpoint}&license=${license}` +
            (payload ? `&data=${encodeURIComponent(JSON.stringify(payload))}` : '');
    }
    else if (method === "POST") {
        url = `${process.env.REACT_APP_API_URL}/connect/index.php?endpoint=${endpoint}&license=${license}`;
        options.body = JSON.stringify(payload);
    }

    return { url, options };
}

function setCache(endpoint, payload, result) {
    const memoKey = JSON.stringify(payload);

    if (payload) {
        if (!cache.has(endpoint)) {
            cache.set(endpoint, new Map([[memoKey, result]]));
        } else {
            cache.get(endpoint).set(memoKey, result)
        }
    } else {
        cache.set(endpoint, result);
    }
}

function getCache(endpoint, payload) {
    const memoKey = JSON.stringify(payload);

    if (!cache.has(endpoint)) return null;
    if (payload) return cache.get(endpoint).get(memoKey) ?? null;

    return cache.get(endpoint);
}

async function handleMemo(endpoint, payload = null, url, options) {
    try {
        const value = getCache(endpoint, payload);

        if (value) {
            globals.debug && console.log(`${endpoint} had memo. Loading.`);
            return value;
        } else {
            globals.debug && console.log(`${endpoint} had no memo. Storing.`);
            let response = await fetch(url, options);
            response = await response.json();

            if (response.Errors)
                throw new Error(response.Title);

            setCache(endpoint, payload, response);
            return response;
        }
    } catch (error) {
        globals.debug && console.log(error);
        throw error;
    }
}

//GET
export async function getProductListingAsync(license, memoize = false) {
    try {
        const endpoint = "external/GetProductListingAsync"
        const { url, options } = buildFetchOptions(endpoint, "GET", license);
        let response;
        globals.debug && console.log(endpoint);

        if (!globals.allowMemoization)
            memoize = false;

        if (memoize)
            return await handleMemo(endpoint, null, url, options);
        else {
            response = await fetch(url, options);
            response = await response.json();

            if (response.Errors)
                throw new Error(response.Title);
            return response;
        }

    } catch (error) {
        globals.debug && console.log(error);
        throw new Error(error.message);
    }
}

export async function getProductItemAsync(license, id, memoize = false) {
    try {
        const endpoint = "external/GetProductItemAsync";
        const payload = { Id: id }
        const { url, options } = buildFetchOptions(endpoint, "GET", license, payload);
        let response;
        globals.debug && console.log(endpoint);

        if (!globals.allowMemoization)
            memoize = false;

        if (memoize)
            return await handleMemo(endpoint, payload, url, options);
        else {
            response = await fetch(url, options);
            response = await response.json();
        }

        if (response.Errors)
            throw new Error(response.Title)
        else
            return response;

    } catch (error) {
        globals.debug && console.log(error);
        throw new Error("Failed to get product details.");
    }
}

export async function getEligibilityAsync(license, id) {
    try {
        const endpoint = "external/GetEligibilityAsync";
        const { url, options } = buildFetchOptions(endpoint, "GET", license, { Id: id });
        let response;
        globals.debug && console.log(endpoint);

        response = await fetch(url, options);
        response = await response.json();

        if (response.Errors)
            throw new Error(response.Title)
        else
            return response;
    } catch (error) {
        globals.debug && console.log(error);
        throw new Error("Failed to get eligibility details.");
    }
}

export async function getGapDetailsAsync(registrationNumber, customerPhone, customerEmail, license) {
    try {
        const endpoint = "external/GetGapDetailsAsync";
        const payload = { registrationNumber, customerPhone, customerEmail };
        const { url, options } = buildFetchOptions(endpoint, "GET", license, payload);
        let response;
        globals.debug && console.log(endpoint);

        response = await fetch(url, options);
        response = await response.json();
        return response;
    } catch (error) {
        globals.debug && console.log(error);
        throw new Error("Failed to get GAP details.");
    }
}

//POST
export async function prePurchaseAsync(prePurchaseRequest, license, memoize = false) {
    try {
        const endpoint = "external/PrePurchaseAsync";
        const { url, options } = buildFetchOptions(endpoint, "POST", license, prePurchaseRequest);
        let response;

        globals.debug && console.log(endpoint, "Request: ", prePurchaseRequest);

        if (!globals.allowMemoization)
            memoize = false;

        response = await fetch(url, options);
        response = await response.json();

        globals.debug && console.log(endpoint, "Response: ", response);

        if (response.Errors)
            throw new Error(response.Title)
        else
            return response;
    } catch (error) {
        globals.debug && console.log(error);
        throw new Error(error.message);
    }
}

export async function completePurchaseAsync(completeRequest, license) {
    try {
        const endpoint = "external/CompletePurchaseAsync";
        const { url, options } = buildFetchOptions(endpoint, "POST", license, completeRequest);
        let response;
        globals.debug && console.log(endpoint, completeRequest);

        response = await fetch(url, options);
        response = await response.json();

        if (response.Errors)
            throw new Error(response.Title)

        globals.debug && console.log(endpoint, "Response: ", response);

        return response;
    } catch (error) {
        globals.debug && console.log(error);
        throw new Error("Failed to send complete purchase request.");
    }
}

export async function storeGapHeldProductAsync(productId, emailAddress, vehiclePurchaseDate = null, license) {
    try {
        const endpoint = "external/StoreGapHeldProductAsync";
        const payload = { productId, emailAddress, vehiclePurchaseDate };
        const { url, options } = buildFetchOptions(endpoint, "POST", license, payload);
        let response;
        globals.debug && console.log(endpoint);

        response = await fetch(url, options);
        response = await response.json();

        if (response.Errors)
            throw new Error(response.Title);

        return response;
    } catch (error) {
        globals.debug && console.log(error);
        throw new Error("Failed to store GAP held product.");
    }
}

