import { useEffect, useState } from "react";
import { getEligibilityAsync } from "../helpers/apiRequestHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { addProduct } from "../redux/checkout";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import { ReactComponent as Failed } from '../styles/svg/status-failed.svg';

import Headbar from "../components/headbar";
import Loading from "../components/loading";
import ErrorPage from "./error";

export default function Eligibility() {
    const { license } = useSelector(state => state.authorise);

    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [checks, setChecks] = useState([])
    const [isDisabled, setIsDisabled] = useState(true);
    const [ineligibile, setIneligibile] = useState(false);

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (license && !data && !error) {
            getEligibilityAsync(license, state.id)
                .then((response) => {
                    setChecks(Array(response.Eligibility.length).fill(false));
                    setData(response)
                })
                .catch((error) => setError(error.message));
        }
    }, [license, data, error]);

    const handleCheck = index => {
        setChecks(previous => {
            const newChecks = [...previous];
            newChecks[index] = !newChecks[index];

            const allEligible = newChecks.every(check => check);
            setIsDisabled(!allEligible);
            return newChecks;
        })
    };

    const handleClick = (option) => {
        if (option === "no")
            setIneligibile(true);
        else
            if (option === "yes") {
                const allEligible = checks.every(check => check);

                if (allEligible) {
                    dispatch(addProduct(state));
                    navigate(`/checkout/${state.id}`);
                } else setIneligibile(true)
            }
    }

    if (error) return <ErrorPage />

    return (
        <>
            <Headbar back={`/product/${state.id}`} backText={"Back To Product"} />

            <div className="flow--32">
                <h3 className="h3">Eligibility questionnaire</h3>
                <p>To be eligibile for our products you must confirm that all of the below apply.</p>

                <div className="checkout--container">
                    <div className="flow--16">
                        {
                            ineligibile ?
                                (
                                    // Customer is ineligible for product (answered no to any question)
                                    <>
                                        <div className="flow--16">
                                            <span style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                                                <Failed />
                                                <span className="h3" style={{ marginLeft: "15px" }}>You are not eligibile for this product.</span>
                                            </span>
                                        </div>
                                    </>
                                ) :
                                (
                                    data ?
                                        // Data received from API and customer is currently not considered ineligible
                                        (
                                            <>
                                                {data.Eligibility.map((question, index) => (
                                                    <div key={`eligibilityQuestion${index}`} className="checks">
                                                        <input
                                                            className="checkboxNative"
                                                            name={`checkboxName--${index}`}
                                                            id={`checkboxId--${index}`}
                                                            type="checkbox"
                                                            onChange={() => handleCheck(index)}
                                                            checked={checks[index]}
                                                        />
                                                        <label
                                                            htmlFor={`checkboxId--${index}`}
                                                            className="eligibility--checks"
                                                        >{question}</label>
                                                    </div>
                                                ))}
                                            </>
                                        ) :
                                        (
                                            // Waiting for API response
                                            <Loading />
                                        )
                                )
                        }

                    </div>
                </div>
            </div>

            {ineligibile ?
                (
                    <div className="button--wrap button--wrap-row">
                        <button
                            className="button button--primary"
                            onClick={() => navigate("/")}
                        >Return to Menu
                            <ArrowRight />
                        </button>
                    </div>
                ) : (
                    <>
                        <div className="button--wrap button--wrap-row">
                            <button
                                disabled={isDisabled}
                                onClick={() => handleClick("yes")}
                                className="button button--primary"
                            >Yes</button >

                            <button
                                onClick={() => handleClick("no")}
                                className="button button--secondary"
                            >No</button>

                        </div>
                    </>
                )
            }

            {process.env.REACT_APP_DEBUG === "true" && <button onClick={() => {
                dispatch(addProduct(state));
                navigate(`/checkout/${state.id}`);
            }}>Debug</button>}
        </>
    )
}
