import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { clearCart, setObjectState, setState } from '../redux/checkout';
import Loading from '../components/loading';
import { completePurchaseAsync } from '../helpers/apiRequestHelper';
import Headbar from '../components/headbar';
import { globals } from '../helpers/misc';

function generateCompletePurchaseRequest(Cart, PrePurchaseResponse, Customer, Vehicle, FinanceOptions, VariantSelection, gateway) {
  const Products = [];
  const OptinDate = new Date().toISOString();
  const ExternalReference = `Acl-${new Date().toISOString()}`;
  let paidByOption;

  if (gateway === "stripe")
    paidByOption = globals.paymentMethodPaidBy.stripe;
  else if (FinanceOptions.FundingSource === "DirectDebit")
    paidByOption = globals.paymentMethodPaidBy.directDebit;
  else if (FinanceOptions.FundingSource === "PremiumFunding")
    paidByOption = globals.paymentMethodPaidBy.premiumFunding;
  else if (FinanceOptions.FundingSource === "Cash")
    paidByOption = globals.paymentMethodPaidBy.cash;

  Object.keys(Cart).forEach(productId => {
    /*
    Each Cart item in the basket may have multiple insurance products associated with it from PrePurchaseResponse
    We need to filter them based on some critera to ensure we are only trying to register a single product per basket item
    */
    const correspondingPrePurchaseResponseProducts = PrePurchaseResponse.Products.filter(product => productId === product.ProductId);
    correspondingPrePurchaseResponseProducts.forEach(product => {

      const { MaxSingleClaim, MaxNoOfClaims, PaidBy, ProductTypeCode, Provider, RateId, Term, WarrantyTierId = null } = product;
      const { cost: RetailPrice, StartDate, name: ProductName } = Cart[productId];

      const productData = {
        MaxSingleClaim,
        MaxNoOfClaims,
        PaymentMethod: "FreeToCustomer", //TODO
        ProductName,
        ProductTypeCode,
        Provider,
        RateId,
        RetailPrice,
        StartDate,
        Term,
        WarrantyStartDate: Cart[productId].StartDate,
        WarrantyTierId,
      };

      if (PaidBy)
        productData.PaidBy = PaidBy;

      const isCorrectPaidByOption = productData?.PaidBy === paidByOption;
      const isNotAVariant = VariantSelection[product.ProductId] === undefined;
      const isCorrectVariantSelection = VariantSelection[product.ProductId] === product.RateId;

      if (isCorrectPaidByOption || isNotAVariant || isCorrectVariantSelection)
        Products.push(productData);
    });
  });

  return {
    Customer,
    Vehicle,
    Products,
    OptinDate,
    ProductInfoHandOverDateTime: OptinDate,
    SalesDate: OptinDate,
    CommencementDate: OptinDate,
    ExternalReference,
    PolicyPurchaseDate: OptinDate,
    UserReference: "",
    FinanceOptions
  }
};

export default function PaymentResponse() {
  // This page sends the CompleteRequest.

  const [params] = useState(new URLSearchParams(useLocation().search));
  const { FinanceOptions, Cart, PrePurchaseResponse, Customer, Vehicle, VariantSelection } = useSelector((state) => state.checkout);
  const { gateway } = useSelector((state) => state.authorise);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { license } = useSelector(state => state.authorise);

  const [Data, setData] = useState(null);
  const [error, setError] = useState(null);

  const mock = {
    "Policies": [
      {
        "Provider": 13,
        "Id": "94/21367",
        "Url": "https://api2demo.pslsynergy.com/Document?UserId=3400&CustId=94&Id=18604&CloudId=1254b81a-cc6c-4260-a7ee-ba2353ed31ec",
        "Document": "",
        "RateId": "DC09404444",
        "Status": true,
        "Message": null
      }
    ],
    "TransactionId": "b89c7e2d-10fb-4724-8fac-23b767edef2d"
  }

  useEffect(() => {
    let timeoutId;

    if (license && !Data && !error) {
      const completeRequest = generateCompletePurchaseRequest(Cart, PrePurchaseResponse, Customer, Vehicle, FinanceOptions, VariantSelection, gateway);
      const completeRequestCopy = structuredClone(completeRequest); //clone object as some properties of the original are read-only
      dispatch(setObjectState({ root: "CompleteRequest", value: completeRequestCopy })); // Store the request for later (payment details page)


      //setData(mock);
      //dispatch(clearCart());
      completePurchaseAsync(completeRequestCopy, license)
        .then(response => setData(response))
        .catch(error => {
          setError(error.message);
          globals.resetRequired = true;
        })
        .finally(() => dispatch(clearCart()));
    }
    if (Data && !error) {
      //dispatch(setState({ root: "CompleteRequestResponse", key: "Data", value: mock }));
      dispatch(setState({ root: "CompleteRequestResponse", key: "Data", value: Data }));

      timeoutId = setTimeout(() => {
        navigate("./success");
      }, 3500);
    }

    return () => { clearTimeout(timeoutId) }
  }, [license, Data, error, dispatch, navigate]);

  return (
    <>
      {error ? (
        <>
          <Headbar back={"/"} backText={"Main Menu"} />
          <div className='checkout--container' style={{ display: 'flex', alignItems: "center" }}>
            <p>Thank you for your purchase.</p>
            <p>Your policies are being processed.</p>
            <p>You will receive an email when registered in the next 24 hours.</p>
            <h1>You may now close this window.</h1>
          </div>
        </>
      ) :
        params.get('redirect_status') === 'succeeded' ?
          (
            <>
              <Headbar showBack={false} />
              <div className='checkout--container' style={{ display: 'flex', alignItems: "center", textAlign: "center" }}>
                <h3>Your payment has been successful, please allow us to process your registration and you will be redirected.</h3>
                <h3>Please do not close or refresh this page.</h3>
                <h3>It may take upwards of thirty seconds to process your request.</h3>
                <Loading />
              </div>
            </>
          )
          :
          (
            <>
              <Headbar showBack={false} />
              <div className='checkout--container' style={{ display: 'flex', alignItems: "center" }}>
                <p>Something went wrong. Click <Link to={"./payment"}>here</Link> to retry payment.</p>
              </div>
            </>
          )
      }
    </>
  );
}
