import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VehicleDetails from '../components/vehicle';
import CartProducts from '../components/cartContainer';
import { useEffect, useState } from 'react';
import '../styles/components/checkbox.scss';
import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import { setObjectState, setStateMany } from '../redux/checkout';
import Headbar from '../components/headbar';
import { prePurchaseAsync } from '../helpers/apiRequestHelper';
import ErrorPage from './error';

import "../styles/pages/checkout.scss"

export default function Checkout() {
  const { Vehicle, Cart, FinanceOptions, PrePurchaseResponse, VariantSelection } = useSelector((state) => state.checkout);
  const vehicleDetailsComplete = Vehicle.RegistrationNumber && Vehicle.Make && Vehicle.Model && Vehicle.Value;

  const { license } = useSelector(state => state.authorise);

  const [Data, setData] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    //Build the PrePurchaseRequest object
    const prePurchaseRequest = {
      RegistrationNumber: Vehicle.RegistrationNumber,
      PurchaseType: FinanceOptions.PurchaseType,
      FinanceType: FinanceOptions.FinanceType,
      FinanceTerm: FinanceOptions.Instalments,
      FinancePayment: FinanceOptions.FinancePayment,
      Make: Vehicle.Make,
      Model: Vehicle.Model,
      VehiclePrice: Vehicle.Value,
      BasketProducts: Object.keys(Cart).map(prod => { return { ProductId: Cart[prod].id, PricingId: Cart[prod].pricingId } })
    };

    if (license && !Data && vehicleDetailsComplete && Object.keys(Cart).length > 0) {
      //only fetch PrePurchase if the vehicle has been entered and there are products in the cart.

      prePurchaseAsync(prePurchaseRequest, license).then(response => setData(response)).catch(error => setError(error.message));
    }

    if (Data && !error) {
      const dataCopy = structuredClone(Data);

      let newTransmission = Data.Vehicle.Transmission;
      if (newTransmission === "Manual gearbox") {
        newTransmission = "Manual";
        dataCopy.Vehicle.Transmission = newTransmission;
      }

      dispatch(setStateMany({
        root: "PrePurchaseResponse",
        updates: [
          { key: "VehicleDataReceived", value: true },
          { key: "Products", value: Data.Products },
          { key: "Vehicle", value: Data.Vehicle }
        ]
      }));

      //dispatch(setObjectState({ root: "Vehicle", value: dataCopy.Vehicle }))
      dispatch(setStateMany({
        root: "Vehicle",
        updates: [
          { key: "EngineCapacityCC", value: Data.Vehicle.EngineCapacityCC },
          { key: "FuelType", value: Data.Vehicle.FuelType },
          { key: "ManufactureDate", value: Data.Vehicle.ManufactureDate },
          { key: "Mileage", value: Data.Vehicle.Mileage },
          { key: "ModelDesc", value: Data.Vehicle.ModelDesc },
          { key: "PurchaseMethod", value: Data.Vehicle.PurchaseMethod },
          { key: "RegistrationDate", value: Data.Vehicle.RegistrationDate },
          { key: "SaleType", value: Data.Vehicle.SaleType },
          { key: "Transmission", value: newTransmission },
          { key: "Type", value: Data.Vehicle.Type },
          { key: "TyreType", value: Data.Vehicle.TyreType },
          { key: "Vin", value: Data.Vehicle.Vin }
        ]
      }));
    }
  }, [license, Data, Cart, dispatch]);

  //every basket item must have a start-date and if applicable, a selected variant
  //Products with no matching pre-purchase product can't have start date applied as the controls do not appear
  const validatePurchasability = () => {
    const productVariantCount = {};
    const cartKeys = Object.keys(Cart);

    cartKeys.forEach(cartProductId => {
      const matchingPprProducts = PrePurchaseResponse.Products.filter(pprProduct => pprProduct.ProductId === cartProductId);
      productVariantCount[cartProductId] = matchingPprProducts.length;
    });

    const everyVariantSelected = cartKeys.every(cartProductId => {
      if (productVariantCount[cartProductId] === 1)
        return true;

      if (Object.hasOwn(VariantSelection, cartProductId) && VariantSelection[cartProductId] !== null)
        return true;

      return false;
    });

    const everyStartDateIsSet = cartKeys.every(cartProductId => Cart[cartProductId].StartDate !== "");

    return everyStartDateIsSet && everyVariantSelected;
  }

  const canPurchase = validatePurchasability();

  const disabledButton =
    <>
      <button disabled={true} className='button button--primary'>
        Continue
        <ArrowRight />
      </button>
    </>
  if (error) return <ErrorPage message={error || "Unauthorised."} />
  return (
    <>
      <Headbar
        back={!id ? '/' : `/product/${id}`}
        backText={!id ? "Main Menu" : "Back To Product"}
      />
      <h1 className='h1'>Order Summary</h1>
      {vehicleDetailsComplete && <VehicleDetails />}

      <CartProducts />

      <div className="wrapper">
        {(Object.keys(Cart).length > 0) ?
          vehicleDetailsComplete ? PrePurchaseResponse.VehicleDataReceived ? canPurchase /*(Object.keys(Cart).every(id => Cart[id]["StartDate"] !== ''))*/ ?
            // Items in cart, vehicle data set, PrePurchase received, and all items dated
            <Link to='/checkout/personal' className='button button--primary'>
              Continue
              <ArrowRight />
            </Link>
            :
            // Products in cart, vehicle data set, PrePurchase received, but still need dates
            disabledButton
            :
            // Products in cart, and vehicle data set. But still waiting for Prepurchase and dates
            disabledButton
            :
            // Products in cart but no vehicle yet - so link to vehicle details input
            <Link to='/checkout/vehicle' className='button button--primary'>
              Continue
              <ArrowRight />
            </Link>
          : null //No products in cart
        }
        <Link to='/' className='button button--secondary'>
          Return to Menu
          <ArrowRight />
        </Link>
      </div>
    </>
  );
}
