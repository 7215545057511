export function calculateLatestDayToBeEighteen() {
    const minimumAgeToPurchase = 18;
    const today = new Date();
    const maxBirthDate = new Date();
    maxBirthDate.setFullYear(today.getFullYear() - minimumAgeToPurchase);

    return maxBirthDate.toISOString().slice(0, 10)
}

export function generateErrorList(pageName, data) {
    const errors = [];

    // Helper function to check if a value is missing or invalid
    const isMissingOrInvalid = (field, errorMsg, invalidCondition = false) => {
        if (!field || invalidCondition) errors.push(errorMsg);
    };

    // Check each field
    if (pageName === "Customer") {
        isMissingOrInvalid(data.Type, "Purchase type is required.", data.Type === "Please Select");
        isMissingOrInvalid(data.Title, "Customer title is required.", data.Title === "Please Select");
        isMissingOrInvalid(data.Forename, "Forename is required.");
        isMissingOrInvalid(data.Surname, "Surname is required.");
        isMissingOrInvalid(data.DateOfBirth, "Date of birth is required.");
        isMissingOrInvalid(data.MobileTelephone, "Telephone number is required and must have a minimum of eleven digits.");

        isMissingOrInvalid(data.EmailAddress, "Email address is required.");
        isMissingOrInvalid(data.EmailAddress, "Email address is incorrectly formatted.", !globals.regex.simpleEmail.test(data.EmailAddress));

        isMissingOrInvalid(data.AddressL1, "Address Line 1 is required.");
        isMissingOrInvalid(data.City, "City is required.");
        isMissingOrInvalid(data.CountyRegionState, "County is required.");
        isMissingOrInvalid(data.PostCode, "Post Code is required.");
    }

    if (pageName === "Financing") {
        isMissingOrInvalid(data.AccountNumber, "Account number must be at least six digits.");
        isMissingOrInvalid(data.SortCode, "Sort code is required.");
        isMissingOrInvalid(data.BankName, "Bank name is required.");
        isMissingOrInvalid(data.AccountHoldersName, "Account holder name is required.");
        isMissingOrInvalid(data.PreferredFirstPaymentDate, "Preferred first payment date is required.");
    }


    return errors;
};

export const globals = {
    debug: process.env.REACT_APP_DEBUG === "true",
    allowMemoization: process.env.REACT_APP_ALLOW_CACHING === "true",
    regex: {
        currency: /^£[1-9]+\d*(\.\d{2})?$/,
        simpleEmail: /^[^@]+@[^@]+\.[^@]+$/
    },
    today: (new Date(Date.now())).toISOString().slice(0, 10),
    paymentMethodPaidBy: {
        stripe: "P",
        directDebit: "P",
        premiumFunding: "D",
        cash: "P"
    },

    // This flag will be read when the root URL is visited.
    // If true, it will wipe all session data
    // in the case that the customer already made a purchase but wanted to explore the app again
    resetRequired: false
}