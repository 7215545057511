import { createSlice } from '@reduxjs/toolkit';

export const authoriseSlice = createSlice({
  name: "authorise",
  initialState: {
    uri: null,
    license: null,
    key: null,
    expires: null,
    gateway: null,
  },
  reducers: {
    setURI: (state, action) => {
      state.uri = action.payload
    },
    setDealer: (state, action) => {
      // Stores the value of data-dealer as passed in through the div entry point declared by the dealership
      state.license = action.payload
    },
    setKey: (state, action) => {
      // Saves the key returned and its expiry time
      state.key = action.payload.access_token
      state.expires = String(new Date(Date.now() + 1 * 60 * 60 * 1000))
    },
    setGateway: (state, action) => {
      // Stores the gateway connection information for later use
      state.gateway = action.payload
    }
  }
})

export const { setDealer, setKey, setGateway, setURI } = authoriseSlice.actions;
export default authoriseSlice.reducer;