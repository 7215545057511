import { globals } from "./misc";

const mocks = {
    "postcode": "GL1 4DD",
    "latitude": 51.858984211764714,
    "longitude": -2.230088729411765,
    "addresses": [
        {
            "formatted_address": [
                "13 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "13",
            "line_1": "13 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "13a Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "13a",
            "line_1": "13a Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "15 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "15",
            "line_1": "15 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "17 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "17",
            "line_1": "17 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "19 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "19",
            "line_1": "19 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "21 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "21",
            "line_1": "21 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "23 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "23",
            "line_1": "23 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "25 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "25",
            "line_1": "25 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "27 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "27",
            "line_1": "27 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "29 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "29",
            "line_1": "29 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "31 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "31",
            "line_1": "31 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "44 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "44",
            "line_1": "44 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "46 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "46",
            "line_1": "46 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "48 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "48",
            "line_1": "48 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "50 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "50",
            "line_1": "50 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "52 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "52",
            "line_1": "52 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "54 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "54",
            "line_1": "54 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "56 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "56",
            "line_1": "56 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "58 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "58",
            "line_1": "58 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "60 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "60",
            "line_1": "60 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "62 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "62",
            "line_1": "62 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "64 Alfred Street",
                "",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "",
            "sub_building_number": "",
            "building_number": "64",
            "line_1": "64 Alfred Street",
            "line_2": "",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "Automotive Compliance Ltd",
                "The Factory",
                "44 Alfred Street",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "The Factory",
            "sub_building_name": "Automotive Compliance Ltd",
            "sub_building_number": "",
            "building_number": "44",
            "line_1": "Automotive Compliance Ltd",
            "line_2": "The Factory",
            "line_3": "44 Alfred Street",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "Centre Pointe Creative Dance Academy Ltd",
                "44 Alfred Street",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "Centre Pointe Creative Dance Academy Ltd",
            "sub_building_number": "",
            "building_number": "44",
            "line_1": "Centre Pointe Creative Dance Academy Ltd",
            "line_2": "44 Alfred Street",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "Centre Pointe School Of Dancing Limited",
                "44 Alfred Street",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "Centre Pointe School Of Dancing Limited",
            "sub_building_number": "",
            "building_number": "44",
            "line_1": "Centre Pointe School Of Dancing Limited",
            "line_2": "44 Alfred Street",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "Flat 1",
                "13 Alfred Street",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "Flat 1",
            "sub_building_number": "1",
            "building_number": "13",
            "line_1": "Flat 1",
            "line_2": "13 Alfred Street",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "Flat 2",
                "13 Alfred Street",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "Flat 2",
            "sub_building_number": "2",
            "building_number": "13",
            "line_1": "Flat 2",
            "line_2": "13 Alfred Street",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        },
        {
            "formatted_address": [
                "The Dance Studio",
                "44 Alfred Street",
                "",
                "Gloucester",
                "Gloucestershire"
            ],
            "thoroughfare": "Alfred Street",
            "building_name": "",
            "sub_building_name": "The Dance Studio",
            "sub_building_number": "",
            "building_number": "44",
            "line_1": "The Dance Studio",
            "line_2": "44 Alfred Street",
            "line_3": "",
            "line_4": "",
            "locality": "",
            "town_or_city": "Gloucester",
            "county": "Gloucestershire",
            "district": "Gloucester",
            "country": "England"
        }
    ]
};

export default function getAddressesFromPostcode(postcode) {
    if (globals.debug) {
        return new Promise(resolve => resolve(mocks));
    }

    if (!postcode) return;

    const headers = {
        "Accept": "*/*"
    };

    return fetch(`https://api.getaddress.io/find/${postcode}?api-key=9iylOp3aukWxs7zw8yB7Rg9725&expand=true`, {
        method: "GET",
        headers
    })
        .then(response => response.json())
        .then(data => data)
        .catch(ex => console.log(ex));
}