import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Required from "../required";
import { setState } from "../../redux/checkout";
import "../../styles/components/inputs.scss"
import { globals } from "../../helpers/misc";

const debug = process.env.REACT_APP_DEBUG === "true";

export default function InputText(
    {
        label,
        identifier,
        initialValue = null,
        required = true,
        dispatchRoot = null,
        dispatchKey = null,
        classNameSuffix = "",
        additionalClasses = null,
        maxLength = null
    }) {
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const htmlId = `InputText${dispatchRoot ? dispatchRoot : ''}${dispatchKey ? dispatchKey : ''}`
    let htmlClassName = `input--text${classNameSuffix ? classNameSuffix : ''}`;
    additionalClasses && (htmlClassName += ' ' + additionalClasses.join(' '));

    useEffect(() => {
        !initialValue && required && setError("Required");
        initialValue && handleFormatting(initialValue);
    }, [initialValue]);

    const dispatchNull = () => {
        debug && dispatchKey && console.log(`${dispatchKey} dispatching null.`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: null }));
    }
    const dispatchValue = value => {
        debug && dispatchKey && console.log(`${dispatchKey} dispatching ${value}`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: value }));
    }

    const acceptRawInput = input => {
        if (input) {
            dispatchValue(input);
            setValue(input);
            setError("")
        } else {
            setValue("")
            dispatchNull();
            setError("Required");
        }
    };

    const applyFormattingRule = (input, regex, errorMessage = "Required") => {
        input = input.replaceAll(regex, "");

        if (input) {
            dispatchValue(input);
            setError("");
            setValue(input);
        } else {
            dispatchNull();
            setError(errorMessage);
            setValue(input);
        }
    };

    const formatEmailAddress = input => {
        if (!input && required)
            setError("Required");

        if (input) {
            if (globals.regex.simpleEmail.test(input))
                setError("");
            else
                setError("Must be a valid email address.");

            dispatchValue(input);
        } else {
            setError("Required");
            dispatchNull();
        }

        setValue(input);
    };

    const formatPhoneNumber = input => {
        input = input.replaceAll(/[^0-9 +]/g, '');

        if (!input || input.length < 11) {
            setError("Minimum eleven digits.");
            dispatchNull();
        } else if (input.length >= 11) {
            setError("")
            const formattedPhoneNumber = input.replaceAll(' ', '').replaceAll('+', '00');
            dispatchValue(formattedPhoneNumber);
        }

        setValue(input);
    }

    const handleFormatting = input => {
        switch (identifier) {
            case "bankName":
            case "accountHoldersName":
            case "forename":
            case "surname":
            case "city":
            case "county":
                applyFormattingRule(input, /\d/g,);
                break;
            case "registrationNumber":
                applyFormattingRule(input.toUpperCase(), /[^0-9a-zA-Z ]/g);
                break;
            case "addressL1":
            case "addressL2":
            case "addressL3":
                applyFormattingRule(input, /[^a-zA-Z0-9 ]/g);
                break;
            case "primaryPhoneNumber":
                formatPhoneNumber(input);
                break;
            case "emailAddress":
                formatEmailAddress(input);
                break;
            case "postCode":
                applyFormattingRule(input, /[^a-zA-Z0-9 ]/g);
                break;
            default:
                acceptRawInput(input);
                break;
        }
    }

    return (
        <div className={htmlClassName}>
            <label htmlFor={htmlId}>
                {label}
                {/* {required && <Required />} */}
            </label>
            <input
                type="text"
                value={value}
                id={htmlId}
                onChange={(e) => handleFormatting(e.target.value)}
                maxLength={maxLength && maxLength}
            >
            </input>
            <span className={`input--sortCodeError ${error ? 'visible' : ''}`}>{error || "✅"}</span>
        </div>
    )
}