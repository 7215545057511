import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setState } from "../../redux/checkout";
import Required from "../required";
import { globals } from "../../helpers/misc";

function convertNumberToSortCode(number) {
    return number.toString().replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3');
}

export default function InputSortCode({ initialValue }) {
    //const [sortCode, setSortCode] = useState(initialValue ? convertNumberToSortCode(initialValue) : "");
    const [value, setValue] = useState("");
    const [error, setError] = useState(value ? "" : "Required");
    const dispatch = useDispatch();

    useEffect(() => {
        setValue(initialValue || "");
        setError(initialValue ? "" : "Required");
    }, [initialValue]);

    const handleChange = (e) => {
        if (e.target.value.length > 8)
            return;
        let value = e.target.value.replace(/\D/g, '');

        if (value.length > 2) value = value.replace(/(\d{2})(\d+)/, '$1-$2');
        if (value.length > 5) value = value.replace(/(\d{2}-\d{2})(\d+)/, '$1-$2');

        if (value.length === 8) {
            globals.debug && console.log(`SortCode dispatching ${typeof value}: ${value}`)
            dispatch(setState({ root: "FinanceOptions", key: "SortCode", value }));
            setError("");
        } else {
            globals.debug && console.log(`SortCode dispatching null`)
            dispatch(setState({ root: "FinanceOptions", key: "SortCode", value: null }));
            setError("Six digits are required.")
        }

        setValue(value);
    };

    return (
        <div className="input--sortCode">
            <label htmlFor="InputSortCode">
                Sort Code
                {/* <Required /> */}
            </label>

            <input
                type="text"
                id="InputSortCode"
                onChange={handleChange}
                value={initialValue || value}
                maxLength={8}
                placeholder="XX-XX-XX"
            >
            </input>
            <span className={`input--sortCodeError ${error ? 'visible' : ''}`}>{error || "✅"}</span>
        </div>
    )
}