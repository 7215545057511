import { useDispatch, useSelector } from 'react-redux';
import { setState, setStateMany } from '../redux/checkout';
import AddressLookup from '../components/checkout/address-lookup';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import { useEffect, useState } from 'react';
import { calculateLatestDayToBeEighteen, generateErrorList } from '../helpers/misc';
import Headbar from '../components/headbar';
import DebuggerButton from '../components/debuggerButton';
import InputDropdown from '../components/checkout/inputDropdown';
import InputText from '../components/checkout/inputText';
import InputDate from '../components/checkout/inputDate';

import "../styles/pages/edit_customer.scss"
import ErrorList from '../components/error-list';

const optinDate = new Date().toISOString();
const reference = `Acl-${new Date().toISOString()}`;

export default function EditCustomer() {
  const { Customer, Cart, GapBlocked, FinanceOptions } = useSelector((state) => state.checkout);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showErrors, setShowErrors] = useState(false);

  const errorList = generateErrorList("Customer", Customer);
  const errorListMap = errorList.map((error, key) => {
    return (
      // <div key={key} className={`errorItem ${showErrors && "visible"}`}>
      <p key={key}>{error}</p>
      // </div>
    )
  })
  const isDisabled = errorList.length === 0;

  const isPrivateSale = Customer.Type === "Private";

  const handleSubmit = () => {
    if (errorList.length > 0) {
      setShowErrors(true);
    } else {
      dispatch(setState({ root: "Customer", key: "CountryCode", value: "GBP" }));

      const isGapInBasket = Object.values(Cart).some(product => product.name === "GAP Insurance");

      if (isGapInBasket && GapBlocked) {
        navigate("/gapcheck");
      } else {
        navigate('/payment');
      }
    }
  }

  return (
    <>
      <Headbar back={"/checkout"} backText={"Back To Basket"} />
      <h1 className="h1">Personal Details</h1>

      {process.env.REACT_APP_DEBUG === "true" && <DebuggerButton type="customer" continueFunction={handleSubmit} />}

      <div className='checkout--container'>
        <InputDropdown
          label={"Private / Company"}
          identifier={"private/Company"}
          dispatchRoot={"Customer"}
          dispatchKey={"Type"}
          initialValue={Customer.Type}
        />

        {isPrivateSale ?
          <>
            <InputDropdown
              label={"Title"}
              identifier={"title"}
              dispatchRoot={"Customer"}
              dispatchKey={"Title"}
              initialValue={Customer.Title}
            />
            <InputText
              label={"Forename"}
              identifier={"forename"}
              dispatchRoot={"Customer"}
              dispatchKey={"Forename"}
              initialValue={Customer.Forename}
            />
            <InputText
              label={"Surname"}
              identifier={"surname"}
              dispatchRoot={"Customer"}
              dispatchKey={"Surname"}
              initialValue={Customer.Surname}
            />
            <InputDate
              label={"Date of Birth"}
              identifer={"dateOfBirth"}
              dispatchRoot={"Customer"}
              dispatchKey={"DateOfBirth"}
              additionalClasses={["editCustomerDateOfBirth"]}
              initialValue={Customer.DateOfBirth}
              min={"1920-01-01"}
              max={calculateLatestDayToBeEighteen()}
            />
          </>
          :
          <>
            <InputText
              label={"Company Name"}
              identifier={"companyName"}
              dispatchRoot={"Customer"}
              dispatchKey={"CompanyName"}
            />
          </>
        }


        <AddressLookup />
        <InputText
          label={"Primary Phone Number"}
          identifier={"primaryPhoneNumber"}
          dispatchRoot={"Customer"}
          dispatchKey={"MobileTelephone"}
          initialValue={Customer.MobileTelephone}
        />
        <InputText
          label={"Email Address"}
          identifier={"emailAddress"}
          dispatchRoot={"Customer"}
          dispatchKey={"EmailAddress"}
          initialValue={Customer.EmailAddress}
        />
      </div>

      {/* <div className={`errorList ${showErrors && "visible"}`}>
        {showErrors && errorList.map((error, index) => <p key={`errorKey-${index}`}>{error}</p>)}
      </div> */}

      <button
        className='button button--primary'
        style={{ width: "100%", fontSize: "1rem" }}
        onClick={handleSubmit}
        disabled={!isDisabled}
      >
        Continue
        <ArrowRight />
      </button>
    </>
  )
}