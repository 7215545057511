import Loading from '../components/loading';
import CardProduct from '../components/card--product';
import Headbar from '../components/headbar';
import { getProductListingAsync } from '../helpers/apiRequestHelper';
import { useEffect, useState } from 'react';
import ErrorPage from './error';
import { globals } from '../helpers/misc';
import { useDispatch, useSelector } from 'react-redux';
import { resetAll } from "../helpers/resetStates";


export default function Products() {
  const { license } = useSelector(state => state.authorise);
  const [Data, setData] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (globals.resetRequired) {
      resetAll(dispatch);
      globals.resetRequired = false;
    }

    if (license && !Data && !error) {
      getProductListingAsync(license, true).then(result => setData(result)).catch(error => setError(error.message));
    }
  }, [license, Data, error])

  if (error) {
    return <ErrorPage message={error || "Unauthorised."} />
  }

  return (
    <>
      {(Data) ? (
        <>
          <Headbar showBack={false} />
          <div className='flow--8'>
            <h1 className='h4 semi'>{Data.Title}</h1>
            {Data.Introduction ?
              <p dangerouslySetInnerHTML={{ __html: Data.Introduction }}></p>
              : null}
          </div>
          <div className='wrapper'>
            {Data.Products.map((product) => {
              return <CardProduct key={product.Id} data={product} />;
            })}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
