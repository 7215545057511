import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeProduct } from '../redux/checkout';
import { ReactComponent as Delete } from '../styles/svg/cross.svg';
import CheckoutRow from './checkout/row';
import InputDate from './checkout/inputDate';
import InputDropdown from './checkout/inputDropdown';
import { globals } from '../helpers/misc';
import ErrorPage from '../pages/error';
import CartProduct from './cartProduct';

//const today = new Date(Date.now()).toISOString().slice(0, 10);

export default function CartProducts({ showContainer = true, showTitle = true }) {
  const { Cart, CartTotal, Vehicle, PrePurchaseResponse, VariantSelection } = useSelector((state) => state.checkout);
  //const { GapBlocked } = useSelector(state => state.checkout);
  const dispatch = useDispatch();
  const vehicleDetailsComplete = Vehicle.RegistrationNumber && Vehicle.Make && Vehicle.Model && Vehicle.Value;
  // let gapCost = 0;
  // let cartTotalCopy = 0;
  // if (Object.keys(Cart).some(product => {
  //   gapCost = Cart[product].cost;
  //   return Cart[product].name === "GAP Insurance"
  // }) && gapBlocker === true) {
  //   cartTotalCopy = CartTotal - gapCost;
  // }

  // if (PrePurchaseResponse?.Products.length === 0) {
  //   return (
  //     <div>No products</div>
  //   )
  // }

  const productVariantCount = {};
  const productVariantMap = new Map([["Please Select", "Please Select"]]);

  Object.keys(Cart).forEach(cartProduct => {
    // Tally up how many available variants there are per product, eg TAW £100, TAW £250
    const matchingPprProducts = PrePurchaseResponse.Products.filter(pprProduct => pprProduct.ProductId === cartProduct);
    matchingPprProducts.forEach(product => {
      if (!productVariantMap.has(product.RateId, product.ProductName)) {
        productVariantMap.set(product.RateId, product.ProductName)
        productVariantCount[cartProduct]++;
      }
    });
  });

  return (
    <>
      <div className={showContainer ? 'checkout--container' : ''}>
        <div className="checkout--container--header">
          {showTitle && <h3 className="h3">Basket Products</h3>}
        </div>

        {Object.keys(Cart).length !== 0 ? (
          <>
            {Object.keys(Cart).map((product, index) => {
              return (
                <div key={`CartProductItem${index}`}>
                  <div className='product'>


                    {/* This code block will visually strike out GAP Insurance in the basket*/}
                    {/* {
                      Cart[product].name === "GAP Insurance" && GapBlocked === true ?
                        (
                          <>
                            <div className='product--blocked'>
                              <span className='product--name--blocked'><Link to={`/product/${Cart[product].id}`}>{Cart[product].name}</Link></span>
                              <span className='product--comment--blocked'>GAP Insurance is subject to a waiting period before being eligible for purchase.</span>

                              <div className='product--details--blocked'>
                                <span className='product--comment--blocked'>You may be found to be eligibile after inputting your vehicle details.</span>
                                <span className='product--cost--blocked'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(Cart[product].cost)}</span>
                                <button
                                  onClick={() => {
                                    dispatch(removeProduct(Cart[product].id));
                                  }}>
                                  <Delete />
                                </button>
                              </div>

                            </div>
                          </>

                        ) : (
                          <>
                            <span className='product--name'><Link to={`/product/${Cart[product].id}`}>{Cart[product].name}</Link></span>
                            <span className='product--cost'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(Cart[product].cost)}</span>
                            <button
                              onClick={() => {
                                dispatch(removeProduct(Cart[product].id));
                              }}>
                              <Delete />
                            </button>
                          </>
                        )
                    } */}
                    <span className='product--name'><Link to={`/product/${Cart[product].id}`}>{Cart[product].name}</Link></span>
                    <span className='product--cost'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(Cart[product].cost)}</span>
                    <button
                      onClick={() => {
                        dispatch(removeProduct(Cart[product].id));
                      }}>
                      <Delete />
                    </button>
                  </div>

                  {
                    // Because Array.find only returns the first element to satisfy the predicate, it will not show both products (PaidBy D and P)
                    vehicleDetailsComplete && PrePurchaseResponse.VehicleDataReceived && PrePurchaseResponse?.Products.find(({ ProductId }) => ProductId === Cart[product].id) ?
                      (
                        <CartProduct itemId={product} />
                      ) :
                      (
                        PrePurchaseResponse.VehicleDataReceived &&
                        <div style={{ marginLeft: "1.5rem" }}>
                          <div>Unfortunately there are no matching products for the given vehicle.</div>
                          <div>You may not continue with this purchase.</div>
                          <div>Please remove this product from the basket, or edit the vehicle details and try again.</div>
                        </div>
                      )
                  }
                </div>
              );
            })}

            <div className='summary'>
              <div className='summary__line'>
                <span className='label'>Total:</span>
                <span className='value bold'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(CartTotal)}</span>
              </div>

              <div className='summary__line'>
                <span className="disclaimer">Prices include Insurance Premium Tax</span>
              </div>
            </div>
          </>
        ) : (
          <p>
            There are no products in the cart. Would you like to <Link to='/'>add some</Link>?
          </p>
        )}
      </div>
    </>
  );
}
