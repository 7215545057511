import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { React, useState } from 'react';

import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import Headbar from '../components/headbar';
import DebuggerButton from '../components/debuggerButton';
import InputText from '../components/checkout/inputText';
import "../styles/pages/edit_vehicle.scss"
import InputCurrency from '../components/checkout/inputCurrency';
import InputDropdown from '../components/checkout/inputDropdown';
import InputCheckbox from '../components/checkout/inputCheckbox';

export default function EditVehicle() {
  const { Vehicle, FinanceOptions } = useSelector((state) => state.checkout);
  const [isFinanceChecked, setIsFinanceChecked] = useState(FinanceOptions.PurchaseType === "FINANCE");
  const navigate = useNavigate();

  const isDisabled = Vehicle.RegistrationNumber && Vehicle.Make && Vehicle.Model && Vehicle.Value;

  const handleSubmit = () => {
    navigate("/checkout");
  }

  return (
    <>
      <Headbar back={"/"} backText={"Main Menu"} />
      <h1 className="h1">Vehicle Details</h1>

      <div className='checkout--container'>
        <InputText
          label={"Registration Number"}
          identifier={"registrationNumber"}
          dispatchRoot={"Vehicle"}
          dispatchKey={"RegistrationNumber"}
          initialValue={Vehicle.RegistrationNumber}
          maxLength={7}
          additionalClasses={["editVehicleRegistration"]}
        />

        <InputText
          label={"Vehicle Make"}
          identifier={"vehicleMake"}
          dispatchRoot={"Vehicle"}
          dispatchKey={"Make"}
          initialValue={Vehicle.Make}
          additionalClasses={["editVehicleMake"]}
        />

        <InputText
          label={"Vehicle Model"}
          identifier={"vehicleModel"}
          dispatchRoot={"Vehicle"}
          dispatchKey={"Model"}
          initialValue={Vehicle.Model}
          additionalClasses={["editVehicleModel"]}
        />

        <InputCurrency
          label={"Vehicle Purchase Price"}
          identifier={"vehiclePurchasePrice"}
          dispatchRoot={"Vehicle"}
          dispatchKey={"Value"}
          initialValue={Vehicle.Value}
          additionalClasses={["editVehicleValue"]}
        />

        <InputCheckbox
          label={"Has the vehicle been purchased on finance?"}
          identifier={"isVehicleFinancePurchase"}
          isChecked={FinanceOptions.PurchaseType === "FINANCE" || isFinanceChecked}
          updaterFn={setIsFinanceChecked}
          dispatchRoot={"FinanceOptions"}
          dispatchKey={"PurchaseType"} // Cash or Finance
        />

        {isFinanceChecked &&
          <>

            <InputDropdown
              label={"Vehicle Finance Type"} //HP,PCP
              identifier={"vehicleFinanceType"}
              dispatchRoot={"FinanceOptions"}
              dispatchKey={"FinanceType"}
              initialValue={FinanceOptions.FinanceType}
            />

            <InputDropdown
              label={"Instalments"}
              identifier={"instalments"} //FinanceTerm //6 months, 12 months etc
              dispatchRoot={"FinanceOptions"}
              dispatchKey={"Instalments"}
              initialValue={FinanceOptions.Instalments}
            />

            <InputCurrency
              label={"Finance Payment"}
              identifier={"financePayment"}
              dispatchRoot={"FinanceOptions"}
              dispatchKey={"FinancePayment"}
              initialValue={FinanceOptions.FinancePayment}
            />
          </>
        }
      </div>

      {process.env.REACT_APP_DEBUG === "true" && <DebuggerButton type={"vehicle"} />}

      <button
        className='button button--primary'
        style={{ width: "100%", fontSize: "1rem" }}
        onClick={handleSubmit}
        disabled={!isDisabled}
      >Continue
        <ArrowRight />
      </button>
    </>
  )
}