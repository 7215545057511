import { useSelector } from "react-redux";
import ACLStripe from '../gateways/stripe';
import Headbar from "../components/headbar";
import ErrorPage from "./error";
import EditFinancing from "./edit_financing";

export default function MakePayment() {
  const { gateway } = useSelector((state) => state.authorise);
  const { CartTotal } = useSelector(state => state.checkout);
  //let gateway = "other"
  //let CartTotal = 999

  // As a precaution, escape from payment if somehow the cart is at £0 or negative.
  if (CartTotal <= 0)
    return <ErrorPage message={"The basket value was invalid."} />

  return (
    <>
      <Headbar back={"/checkout"} backText={"Back To Basket"} />
      <h1>Make a payment of {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(CartTotal)}</h1>


      {gateway === "stripe" && <ACLStripe />}
      {gateway === "other" && <EditFinancing />}
    </>
  )
}