import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '../styles/svg/ChevronLeft.svg';
import { ReactComponent as CartImage } from '../styles/svg/Cart.svg';

import '../styles/components/headbar.scss';
import { useSelector } from 'react-redux';

export default function Headbar({ back, backText, showBack = true }) {
  const { Cart } = useSelector((state) => state.checkout);
  const { pathname } = useLocation();

  const numberOfProductsInCart = Object.keys(Cart).length;
  let target = "/checkout"

  if (showBack === false) {
    return (
      <>
        <div className='headbar'>
          <span></span>
          <Link className='headbar__icon' to={target}>
            <CartImage className='cart__icon' />
            <span className='headbar__indicator'>{numberOfProductsInCart}</span>
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='headbar'>
        {pathname === target ? (
          <>
            <Link className='button--back' to={back}>
              <ChevronLeft />
              <span className='button--label'>{backText ? backText : "Back"}</span>
            </Link>

            <a className='headbar__icon'>
              <CartImage className='cart__icon' />
              <span className='headbar__indicator'>{numberOfProductsInCart}</span>
            </a>
          </>
        ) : (
          <>
            <Link className='button--back' to={back}>
              <ChevronLeft />
              <span className='button--label'>{backText ? backText : "Back"}</span>
            </Link>

            <Link className='headbar__icon' to={target}>
              <CartImage className='cart__icon' />
              <span className='headbar__indicator'>{numberOfProductsInCart}</span>
            </Link>
          </>
        )}

      </div>
    </>
  );
}
