import { useSelector } from "react-redux"
import Loading from '../components/loading';
import Headbar from "../components/headbar";
import { globals } from "../helpers/misc";
import { useLocation } from "react-router-dom";

export default function PaymentDetails() {
    // This page displays when the payment has been successful, and products have been registered.

    const { CompleteRequest } = useSelector(state => state.checkout)
    const { CompleteRequestResponse } = useSelector(state => state.checkout);

    console.log(CompleteRequest, CompleteRequestResponse)

    if (CompleteRequestResponse.Data === null) {
        return <Loading />
    } else {
        globals.resetRequired = true;
    }

    const details = CompleteRequest.Products.map((product, index) => {
        const rateId = product.RateId;
        const correspondingResponse = CompleteRequestResponse.Data.Policies.find(product => product.RateId === rateId);
        const document = correspondingResponse?.Url || "";// || correspondingResponse.Document;
        const formattedPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(product.RetailPrice)
        const productString = `${product.Term} month ${product.Provider} ${product.ProductName} - ${formattedPrice}`;

        return (
            <div key={`PurchasedProduct${index}`}>
                <p>{productString}</p>
                <a href={document} target="_blank" rel="noreferrer noopener" style={{ paddingLeft: "25px", fontSize: "1.25rem" }}>View policy document</a>
            </div>
        )
    });

    return (
        <>
            <Headbar back={"/"} backText={"Main Menu"} />

            <div style={{ margin: "15px" }}>
                <div>
                    {details.length === 1 ? <h2>Your purchase:</h2> : <h2>Your purchases</h2>}
                </div>

                <div className="checkout--container" style={{ marginTop: "1rem" }}>
                    {details}
                </div>


            </div>
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
                <p>You will receive a copy of this purchase to your email address.</p>
                <p>Thank you for your purchase.</p>
                <p>You may now close this window.</p>
            </div>
        </>
    )
}