import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronRight } from '../styles/svg/ChevronRight.svg';

import Content from './content';
import Section from './section';

import '../styles/components/estimate.scss';

export default function Estimate({ data, intro, product }) {
  const firstPricingOption = data[0];
  const [selectedPrice, setSelectedPrice] = useState([firstPricingOption.Term, firstPricingOption.Price, firstPricingOption.Id]);

  const [id, name] = [...product];
  const [, cost, pricingId] = [...selectedPrice];

  return (
    <>
      <Section class='section--shaded'>
        <div className='estimate estimate-info flow--12'>
          {intro ?
            <>
              <Content title="Estimate">
                <p>{intro}</p>
              </Content>
              <hr className='estimate__divider' />
            </>
            : null}
          <div className='estimate__calculator flow--12'>
            <h4 className='size--3 semi'>Term to cover:</h4>
            <div className='wrapper'>
              {data.map((option, index) => {
                return (
                  <button
                    key={index}
                    className={option.Price === cost ? 'button button--select button--term is--active' : 'button button--select button--term'}
                    onClick={() => setSelectedPrice([option.Term, option.Price, option.Id])}>
                    {option.Term}
                  </button>
                );
              })}
            </div>
            {/* <h4 className='size--3 semi'>Type of cover:</h4>
            <div className='wrapper'>
              <button onClick={() => setVAT(true)} className={vat ? 'button button--select is--active' : 'button button--select'}>
                Personal
              </button>
              <button onClick={() => setVAT(false)} className={vat ? 'button button--select' : 'button button--select is--active'}>
                Business
              </button>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className='estimate__value'>
                <h4 className='h4 stacked bold primary--200'>
                  {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(cost)}
                  <span className="disclaimer right">Includes Insurance Premium Tax</span>
                </h4>
              </div>
            </div>
            <div className='wrapper'>
              <Link className="button button--primary button--width"
                to={`/eligibility/${id}`}
                state={{ id, name, cost, pricingId, StartDate: '' }}>
                <span className='label'>Buy Now</span>
                <ChevronRight />
              </Link>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}
