import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Required from "../required";
import { setStartDate, setState } from "../../redux/checkout";
import "../../styles/components/inputs.scss"
import { globals } from "../../helpers/misc";

export default function InputDate(
    {
        label,
        identifer,
        initialValue = null,
        required = true,
        dispatchRoot = null,
        dispatchKey = null,
        classNameSuffix = "",
        additionalClasses = null,
        purchaseId = null, // For updating StartDate on a specific basket item
        min = null,
        max = null,
    }) {
    const [value, setValue] = useState("");
    const [error, setError] = useState(required ? "Required" : "");
    const dispatch = useDispatch();
    const htmlId = `InputText${dispatchRoot ? dispatchRoot : ''}${dispatchKey ? dispatchKey : ''}`
    let htmlClassName = `input--date${classNameSuffix ? classNameSuffix : ''}`;
    additionalClasses && (htmlClassName += ' ' + additionalClasses.join(' '));

    useEffect(() => {
        initialValue && handleChange(initialValue);
    }, [initialValue]);

    const dispatchValue = date => {
        if (purchaseId) {
            globals.debug && dispatchKey && console.log(`${dispatchKey} on product ${purchaseId} dispatching ${typeof date}: ${date}`);
            dispatchRoot && dispatchKey && dispatch(setStartDate({ root: dispatchRoot, purchaseId, value: date }));
        } else {
            globals.debug && dispatchKey && console.log(`${dispatchKey} dispatching ${typeof value}: ${date}`);
            dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: date }));
        }
    }

    const dispatchNull = () => {
        if (purchaseId) {
            globals.debug && dispatchKey && console.log(`${dispatchKey} on product ${purchaseId} dispatching null`);
            dispatchRoot && dispatchKey && dispatch(setStartDate({ root: dispatchRoot, purchaseId, value: null }));
        } else {
            globals.debug && dispatchKey && console.log(`${dispatchKey} dispatching null`);
            dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: null }));
        }
    }

    const handleChange = input => {
        const inputAsDate = new Date(input);

        if (min) {
            const minDate = new Date(min);

            if (inputAsDate < minDate) {
                // Reformat yyyy-mm-dd to dd/mm/yyyy to match the visuals of the datepicker controls
                const ddmmyyyy = `${min.slice(8, 10)}/${min.slice(5, 7)}/${min.slice(0, 4)}`;

                dispatchNull();
                setError(`Minimum of ${ddmmyyyy} required.`);
                setValue(input);
                return;
            }
        }

        if (max) {
            const maxDate = new Date(max);

            if (inputAsDate > maxDate) {
                // Reformat yyyy-mm-dd to dd/mm/yyyy to match the visuals of the datepicker controls
                const ddmmyyyy = `${max.slice(8, 10)}/${max.slice(5, 7)}/${max.slice(0, 4)}`;

                dispatchNull();
                setError(`Maximum of ${ddmmyyyy} allowed.`);
                setValue(input);
                return;
            }
        }

        if (input) {
            dispatchValue(input);
            setError("");
            setValue(input);
        } else
            setError("Required");
    }

    return (
        <div className={htmlClassName}>
            <label htmlFor={htmlId}>
                {label}
                {/* {required && <Required />} */}
            </label>

            <input
                type="date"
                value={value}
                id={htmlId}
                onChange={event => handleChange(event.target.value)}
                min={min && min}
                max={max && max}
            // onKeyDown={(e) => e.preventDefault()}
            >
            </input>
            <span className={`input--dateError ${error ? 'visible' : ''}`}>{error || "✅"}</span>
        </div>
    )
}