import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDealer, setGateway, setURI } from './redux/auth';
import useLicense from './hooks/useLicense';
import Menu from './pages/menu';

import convert from 'color-convert';

import './styles/base.scss';
import Loading from './components/loading';
import { setGapBlocker } from './redux/checkout';
import ErrorPage from './pages/error';

export default function LicenseCheck({ license }) {
  const dispatch = useDispatch();
  const data = useLicense(license);

  const [phpLoadingError, setPhpLoadingError] = useState(false);

  useEffect(() => {
    let errorTimeout;
    let reloadTimeout;

    if (data) {
      // Redux store, when useLicense has data, store it in the respective containers
      dispatch(setDealer(license));
      dispatch(setGateway(data.gateway));
      dispatch(setURI(data.whitelistURI));
      dispatch(setGapBlocker(data.gapBlocker));

      var root = document.querySelector(':root');
      if (data.primary_colour) {
        const hsl = convert.hex.hsl(data.primary_colour);

        root.style.setProperty('--hue', hsl[0]);
        root.style.setProperty('--saturation', `${hsl[1]}%`);
        root.style.setProperty('--lightness', `${hsl[2]}%`);

        if (hsl[2] < 60) {
          root.style.setProperty('--standout', `hsl(${hsl[0]},10%,98%)`);
        } else {
          root.style.setProperty('--standout', `hsl(${hsl[0]},36%,8%)`);
        }
      }
    } else {
      errorTimeout = setTimeout(() => setPhpLoadingError(true), 5000);
      // reloadTimeout = setTimeout(() => window.location.reload(), 7500)
    }

    return () => {
      errorTimeout && clearTimeout(errorTimeout);
      // reloadTimeout && clearTimeout(reloadTimeout);
    }
  }, [dispatch, data, license]);

  // If can't contact the PHP middleware
  if (phpLoadingError) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Loading />
        {/* Cannot connect. Retrying... */}
        Cannot connect. Please try again.
      </div>
    )
  }

  // If dealer license is invalid.
  if (data?.error) return <ErrorPage headbar={false} message={"Invalid license!"} />

  //If all OK
  return data ? <Menu /> : <Loading />
}