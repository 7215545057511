import { createSlice } from '@reduxjs/toolkit';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    Cart: {},
    GapBlocked: false,
    CartTotal: 0,
    VariantSelection: {},
    Customer: {
      CompanyName: null,
      Forename: null,
      Surname: null,
      DateOfBirth: null,
      AddressL1: '',
      AddressL2: '',
      AddressL3: '',
      AddressL4: '',
      AddressL5: '',
      City: '',
      CountyRegionState: '',
      CountryCode: 'GBP',
      PostCode: '',
      MobileTelephone: null,
      Telephone: null,
      EmailAddress: null,
      Title: null,
      Type: null
    },
    FinanceOptions: {
      FundingSource: "Cash", //Cash, DirectDebit, PremiumFunding,
      PurchaseType: "CASH", // cash or finance
      FinanceType: null, // hp, pcp
      FinancePayment: null,
      Deposit: null,
      LoanAmount: null,
      //FinanceAmount: null, //Same thing as Loan Amount??
      AnnualMileage: null,
      Instalments: null, //Same thing as FinanceTerm (6 month, 12 month, etc)
      //FinanceTerm: null, //Same thing as Instalments
      BalloonPayment: null,
      FinanceCompany: null,
      CommencementDate: null,
      BankDetails: {
        AccountNumber: null,
        BankName: null,
        SortCode: null,
        AccountHoldersName: null,
        PreferredFirstPaymentDate: null,
      }
    },
    Vehicle: {
      RegistrationNumber: null,
      Make: null,
      Model: null,
      Vin: null,
      Value: null,
      EngineCapacityCC: null,
      FuelType: null,
      ManufactureDate: null,
      Mileage: null,
      ModelDesc: null,
      PurchaseMethod: null,
      RegistrationDate: null,
      SaleType: null,
      Transmission: null,
      Type: null,
      TyreType: null
    },
    Policy: {
      CommencementDate: null,
      ExternalReference: null,
      OptinDate: null,
      PolicyPurchaseDate: null,
      ProductInfoHandOverDateTime: null,
      SalesDate: null,
      UserReference: null
    },
    PrePurchaseResponse: {
      VehicleDataReceived: false,
      Products: [
        {
          ProductId: null,
          TermText: null,
          Term: null,
          RateId: null,
          ProductName: null,
          WarrantyTierId: null,
          ProductTypeCode: null,
          SuggestedRetailPrice: null
        }
      ],
      Vehicle: {
        FinanceType: null,
        Vin: null,
        EngineCapacityCC: null,
        FuelType: null,
        ManufactureDate: null,
        Mileage: null,
        ModelDesc: null,
        PurchaseMethod: null,
        RegistrationDate: null,
        SaleType: null,
        Transmission: null,
        Type: null,
        TyreType: null
      }
    },
    CompleteRequest: {
      Customer: null,
      Vehicle: null,
      Products: null,
      OptinDate: null,
      ProductInfoHandOverDateTime: null,
      SalesDate: null,
      CommencementDate: null,
      ExternalReference: null,
      PolicyPurchaseDate: null,
      UserReference: null,
      // Financial Data
      FundingSource: null,
      BankDetails: null,
      Deposit: null, //int
      FinanceAmount: null, //decimal
      LoanAmount: null, //decimal
      AnnualMileage: null, //int
      FinanceTerm: null, //int ??
      Instalments: null, //int
      FinanceCompany: null, //str
      FinancePayment: null, //str
    },
    CompleteRequestResponse: {
      Data: null
    }
  },
  reducers: {
    addProduct: (state, action) => {
      // Do not allow the same product to be added twice.
      // This prevents the basket cost doubling when running in React.StrictMode during development.

      if (state["Cart"][action.payload.id] === undefined) {
        state['Cart'][action.payload.id] = action.payload;

        state.Cart[action.payload.id].StartDate = "";

        // Modify GAP cost to 0 if gapBlocked
        //state.CartTotal += (action.payload.name === "GAP Insurance" && state.GapBlocked === true) ? 0 : action.payload.cost;
        state.CartTotal += action.payload.cost;
      }
    },
    removeProduct: (state, action) => {
      // Ensure the product is actually in the basket before attempting to remove it.
      // This is needed to prevent crashes in certain scenarios.
      if (state.Cart[action.payload] === undefined)
        return;

      const { [action.payload]: productToRemove, ...update } = state.Cart;
      // Modify GAP cost to 0 if gapBlocked
      //state.CartTotal -= (productToRemove.name === "GAP Insurance" && state.GapBlocked === true) ? 0 : productToRemove.cost;

      state.CartTotal -= productToRemove.cost;
      state.PrePurchaseResponse.Products = state.PrePurchaseResponse.Products.filter(prod => prod.ProductId !== action.payload); //Directly filter the response      
      state.Cart = update;
    },
    setIDD: (state, action) => {
      // Confirm read IDD
      state['iddRead'] = action.payload;
    },
    clearCart: (state) => {
      state.Cart = {};
      state.CartTotal = 0;
      state.VariantSelection = {};
    },
    clearRequestsAndResponses: (state) => {
      // Call once the payment has succeeded or failed.
      // If the customer happens to start the app from the beginning then there won't be any old request/response data in the store.

      //state.PrePurchaseRequest = {};
      state.PrePurchaseResponse = {};
      state.CompleteRequest = {};
      state.CompleteRequestResponse = {};
    },
    setStateBasic: (state, action) => {
      state[action.payload.root][action.payload.key] = action.payload.value;
    },
    setState: (state, action) => {
      // Search recursively through the state object to allow for updating nested objects.
      // The initial state object MUST contain the key you are searching for, otherwise the function will not
      // add it (how would you know where it goes?).

      // The ONLY exception is if the initial state is an empty object, in which case the key will be added at the base.

      const recursiveSearch = (obj, searchKey, newValue) => {
        const copyObj = { ...obj };
        const entries = Object.entries(copyObj);

        for (const [key, value] of entries) {
          if (key === searchKey) {
            copyObj[key] = newValue;
          } else if (value !== null && typeof value === "object" && !Array.isArray(value)) {
            copyObj[key] = recursiveSearch(value, searchKey, newValue);
          }
        }

        return copyObj;
      }

      let newObj;
      if (Object.keys(state[action.payload.root])?.length === 0)
        newObj = { [action.payload.key]: action.payload.value }
      else
        newObj = recursiveSearch(state[action.payload.root], action.payload.key, action.payload.value);

      return { ...state, [action.payload.root]: newObj }
    },
    setStateMany: (state, action) => {
      // Allows the updating of a bunch of state at once instead of needing to invoke multiple lines of setState
      // Usage: send an object with two kvp, root: Root, and updates: [{key:key, value:value}]
      const { root, updates } = action.payload;
      if (state[root]) {
        updates.forEach(({ key, value }) => {
          state[root][key] = value;
        });
      }
    },
    setBaseValues: (state, action) => {
      const { root, newValues } = action.payload;

      // Create a shallow copy of the state and the nested object to be updated
      const copyState = {
        ...state,
        [root]: {
          ...state[root],
        },
      };

      // Overwrite any matching keys in the nested object
      for (const [key, value] of Object.entries(newValues)) {
        if (Object.hasOwn(copyState[root], key)) {
          copyState[root][key] = value;
        }
      }

      return copyState;
    },
    setObjectState: (state, action) => {
      state[action.payload.root] = action.payload.value;
    },
    setStartDate: (state, action) => {
      // Used for updating StartDate on a specific basket item
      state[action.payload.root][action.payload.purchaseId].StartDate = action.payload.value;
    },
    setGapBlocker: (state, action) => {
      state.GapBlocked = action.payload;
    }
  },
});

export const { addProduct, removeProduct, clearCart, clearRequestsAndResponses, setIDD, setBaseValues, setStateBasic, setState, setStateMany, setObjectState, setStartDate, setGapBlocker } = checkoutSlice.actions;
export default checkoutSlice.reducer;
