import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Required from "../required";
import { setState } from "../../redux/checkout";
import "../../styles/components/inputs.scss"
import { globals } from "../../helpers/misc";

export default function InputCurrency(
    {
        identifier,
        label,
        required = true,
        dispatchRoot = null,
        dispatchKey = null,
        classNameSuffix = "",
        additionalClasses = null,
        initialValue = null,
        maxLength = null
    }) {
    const [value, setValue] = useState("");
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const htmlId = `InputText${dispatchRoot ? dispatchRoot : ''}${dispatchKey ? dispatchKey : ''}`
    let htmlClassName = `input--text${classNameSuffix ? classNameSuffix : ''}`;
    additionalClasses && (htmlClassName += ' ' + additionalClasses.join(' '));

    useEffect(() => {
        setValue(initialValue ? `£${initialValue}` : "");
        setError(initialValue ? "" : required ? "Required" : "");
    }, [initialValue]);

    const dispatchValue = value => {
        value = Number(value.slice(1));

        globals.debug && dispatchKey && console.log(`${dispatchRoot}: ${dispatchKey} dispatching ${typeof value}: ${value}`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value }));
    }
    const dispatchNull = () => {
        globals.debug && dispatchKey && console.log(`${dispatchKey} dispatching null`);
        dispatchRoot && dispatchKey && dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: null }));
    }

    const formatCurrency = input => {
        // Using a text field for a number to allow for £ symbol prefix.
        if (Number(input.slice(1)) > 500000)
            return;

        // Prefix £ symbol
        if (input === "")
            input = "£";

        // Force first char to be £
        if (input.at(0) !== "£") {
            input = "£" + input;
        }

        // Do not allow a decimal point or 0 as the first character
        if (input.at(1) === "." || input.at(1) === "0")
            return;

        // Remove non digits, decimal, or pound symbol
        input = input.replaceAll(/[^0-9.£]/g, "");

        // Only allow one £ symbol
        if (input.at(-1) === "£" && input.length > 1)
            return;

        // Only allow two decimal places
        if (input.match(/[0-9]+\.[0-9]{3}/g))
            input = input.slice(0, -1);

        // Only allow one .
        const dotCount = input.match(/\./g)?.length || 0;
        if (dotCount > 1) {
            input = input.slice(0, -1);
        }

        if (globals.regex.currency.test(input))
            setError("");
        else
            setError("Must be a valid currency quantity.");

        if (input === "£")
            dispatchNull();
        else
            dispatchValue(input);

        setValue(input);
    };

    return (
        <div className={htmlClassName}>

            <label htmlFor={htmlId}>
                {label}
                {/* {required && <Required />} */}
            </label>
            <input
                type="text"
                value={value}
                id={htmlId}
                onChange={(e) => formatCurrency(e.target.value)}
                maxLength={maxLength && maxLength}
                placeholder="£0.00"
            >
            </input>
            <span className={`input--sortCodeError ${error ? 'visible' : ''}`}>{error || "✅"}</span>

        </div>
    )
}