import { BrowserRouter, MemoryRouter, Routes, Route } from 'react-router-dom';
import Products from './products';
import Checkout from './checkout';
import EditCustomer from './edit_customer';
import EditVehicle from './edit_vehicle';
import EditFinancing from './edit_financing';
import ProductInfo from './product-info';
import PaymentResponse from './payment-response';
import MakePayment from './payment';
import '../styles/components/intro.scss';
import '../styles/checkout/container.scss';
import '../styles/components/buttons.scss';
import PaymentDetails from './payment-details';
import Slug from '../components/slug';
import ScrollToTop from '../components/scroll-to-top';
import GapCheck from './gap-check';
import Eligibility from './eligibility';
import ErrorBoundary from '../components/error-boundary';

export default function Menu() {
  return (
    <>
      <MemoryRouter>
        {/* <BrowserRouter> */}
        <ErrorBoundary>
          <ScrollToTop />
          <Routes>
            {/* <Route path='/' element={<EditCustomer />} /> */}
            <Route path='/' element={<Products />} />
            <Route path='/product/:id' element={<ProductInfo />} />
            <Route path='/eligibility/:id' element={<Eligibility />} />
            <Route path='/checkout/' element={<Checkout />} />
            <Route path='/checkout/:id' element={<Checkout />} />
            <Route path='/checkout/personal' element={<EditCustomer />} />
            <Route path='/checkout/vehicle' element={<EditVehicle />} />
            <Route path='/payment' element={<MakePayment />} />
            <Route path='/payment/response' element={<PaymentResponse />} />
            <Route path='/payment/response/success' element={<PaymentDetails />} />
            <Route path='/slug/:id' element={<Slug />} />
            <Route path='/gapcheck' element={<GapCheck />} />
          </Routes>
        </ErrorBoundary>
      </MemoryRouter>
      {/* </BrowserRouter> */}
    </>
  );
}
