import { setObjectState, setStartDate, setStateBasic, setState, setStateMany, clearCart } from "../redux/checkout";
import { globals } from "./misc";

export function resetAll(dispatch) {
    globals.debug && console.log("Resetting all data")
    resetCart(dispatch);
    resetVehicle(dispatch);
    resetCustomer(dispatch);
    resetPrePurchaseResponse(dispatch);
    resetCompletePurchaseResponse(dispatch);
}

export function resetCart(dispatch) {
    dispatch(clearCart());
}

export function resetVehicle(dispatch) {
    dispatch(setObjectState({
        root: "Vehicle", value: {
            RegistrationNumber: null,
            Make: null,
            Model: null,
            Vin: null,
            Value: null,
            EngineCapacityCC: null,
            FuelType: null,
            ManufactureDate: null,
            Mileage: null,
            ModelDesc: null,
            PurchaseMethod: null,
            RegistrationDate: null,
            SaleType: null,
            Transmission: null,
            Type: null,
            TyreType: null
        }
    }));
}

export function resetCustomer(dispatch) {
    dispatch(setObjectState({
        root: "Customer", value: {
            Forename: null,
            Surname: null,
            DateOfBirth: null,
            AddressL1: '',
            AddressL2: '',
            AddressL3: '',
            AddressL4: '',
            AddressL5: '',
            City: '',
            CountyRegionState: '',
            CountryCode: 'GBP',
            PostCode: '',
            MobileTelephone: null,
            Telephone: null,
            EmailAddress: null,
            Title: null,
            Type: null
        }
    }));
}

export function resetPrePurchaseResponse(dispatch) {
    dispatch(setObjectState({
        root: "PrePurchaseResponse", value: {
            VehicleDataReceived: false,
            Products: [
                {
                    ProductId: null,
                    TermText: null,
                    Term: null,
                    RateId: null,
                    ProductName: null,
                    WarrantyTierId: null,
                    ProductTypeCode: null,
                    SuggestedRetailPrice: null
                }
            ],
            Vehicle: {
                FinanceType: null,
                Vin: null,
                EngineCapacityCC: null,
                FuelType: null,
                ManufactureDate: null,
                Mileage: null,
                ModelDesc: null,
                PurchaseMethod: null,
                RegistrationDate: null,
                SaleType: null,
                Transmission: null,
                Type: null,
                TyreType: null
            }
        }
    }));
}

export function resetDirectDebitDetails(dispatch) {
    dispatch(setState({
        root: "FinanceOptions",
        key: "BankDetails",
        value: {
            AccountNumber: null,
            BankName: null,
            SortCode: null,
            AccountHoldersName: null,
            PreferredFirstPaymentDate: null,
        }
    }));
}

export function resetPremiumFundingDetails(dispatch) {
    dispatch(setStateMany({
        root: "FinanceOptions",
        updates: [
            { key: "FinanceCompany", value: "" },
            { key: "FinanceTerm", value: "" },
            { key: "Instalments", value: null },
            { key: "Deposit", value: 0 },
            { key: "FinancePayment", value: 0 },
            { key: "LoanAmount", value: 0 },
            { key: "BalloonPayment", value: 0 },
            { key: "AnnualMileage", value: 0 },
        ]
    }));
}

export function resetCompletePurchaseRequest(dispatch) {
    dispatch(setObjectState({
        root: "CompleteRequest", value: {
            Customer: null,
            Vehicle: null,
            Products: null,
            OptinDate: null,
            ProductInfoHandOverDateTime: null,
            SalesDate: null,
            CommencementDate: null,
            ExternalReference: null,
            PolicyPurchaseDate: null,
            UserReference: null,
            // Financial Data
            FundingSource: null,
            BankDetails: null,
            Deposit: null, //int
            FinanceAmount: null, //decimal
            LoanAmount: null, //decimal
            AnnualMileage: null, //int
            FinanceTerm: null, //int ??
            Instalments: null, //int
            FinanceCompany: null, //str
            FinancePayment: null, //str
        }
    }));
}

export function resetCompletePurchaseResponse(dispatch) {
    dispatch(setState({
        root: "CompleteRequestResponse", key: "Data", value: {
            Data: null
        }
    }));
}



