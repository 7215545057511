import { useDispatch, useSelector } from "react-redux";
import { setObjectState, setState, setStateMany } from "../redux/checkout";
import { useNavigate } from "react-router-dom";

export default function DebuggerButton({ type, continueFunction = null }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { CompleteRequest, FinanceOptions } = useSelector(state => state.checkout)

    if (type === "customer") {
        return <button onClick={() => {
            const value = {
                Type: "Private",
                Title: "Mr",
                Forename: "Forename",
                Surname: "Surname",
                DateOfBirth: "2000-01-01",
                MobileTelephone: "07000000000",
                EmailAddress: "masked@email.com",
                CountryCode: "GBP",
                AddressL1: "Line 1",
                City: "Testopia",
                CountyRegionState: "County",
                PostCode: "GU14HR"
            }

            dispatch(setStateMany({
                root: "Customer",
                updates: [
                    { key: "Type", value: "Private" },
                    { key: "Title", value: "Mr" },
                    { key: "Forename", value: "DEBUGFNAME" },
                    { key: "Surname", value: "DEBUGSNAME" },
                    { key: "DateOfBirth", value: "2000-01-01" },
                    { key: "MobileTelephone", value: "07000000000" },
                    { key: "EmailAddress", value: "masked@email.com" },
                    { key: "CountryCode", value: "GBP" },
                    { key: "AddressL1", value: "TESTLINE1" },
                    { key: "AddressL2", value: "TESTL2" },
                    { key: "AddressL3", value: "TESTL3" },
                    { key: "City", value: "TESTCITY" },
                    { key: "CountyRegionState", value: "COUNTY" },
                    { key: "PostCode", value: "GU14HR" }
                ]
            }));
            dispatch(setState({ root: "CompleteRequest", key: "Customer", value }))

            if (continueFunction !== null) {
                //continueFunction();
            }
        }}>Debug</button>
    }

    if (type === "vehicle") {
        return <button onClick={() => {
            dispatch(setStateMany(
                {
                    root: "Vehicle",
                    updates: [
                        { key: "RegistrationNumber", value: "EN72KBX" },
                        { key: "Make", value: "Ford" },
                        { key: "Model", value: "Fiesta" },
                        { key: "Value", value: 11000 }
                    ]
                }
            ));
            //navigate('/checkout');
        }}>Debug</button>
    }

    if (type === "directdebit") {
        const currentCustomer = FinanceOptions.BankDetails && Object.entries(FinanceOptions.BankDetails).map(([key, value]) => <p key={key}>{key}-{value}</p>)
        const complete = CompleteRequest.BankDetails && Object.entries(CompleteRequest.BankDetails).map(([key, value]) => <p key={key}>{key}-{value}</p>)
        return (
            <>


                <button
                    style={{ height: "50px" }}
                    onClick={() => {

                        const value = {
                            AccountNumber: 99999999,
                            BankName: "DebugBank",
                            SortCode: "99-99-99",
                            AccountHoldersName: "Sir Debugsalot",
                            PreferredFirstPaymentDate: "2024-01-01"
                        }
                        dispatch(setState({ root: "FinanceOptions", key: "BankDetails", value }))
                    }}>
                    DEBUG DIRECT DEBIT CURRENT CUSTOMER
                </button>

                {/* <h1>Current Customer</h1>
                {currentCustomer}
                <h1>Complete Request</h1>
                {complete} */}
            </>
        )
    }
    if (type === "premiumfunding") {
        const currentCustomer = FinanceOptions && Object.entries(FinanceOptions).map(([key, value]) => {
            if (key !== "BankDetails")
                return <p key={key}>{key}-{value}</p>
        });
        const complete = CompleteRequest.PremiumFundingDetails && Object.entries(CompleteRequest.PremiumFundingDetails).map(([key, value]) => <p key={key}>{key}-{value}</p>);

        return (
            <>
                <button
                    style={{ height: "50px" }}
                    onClick={() => {

                        const value = {
                            FundingSource: "PremiumFunding",
                            FinanceType: "HP",
                            FinanceTerm: 24,
                            FinancePayment: 500,
                            Instalments: 30,
                            Deposit: 1000,
                            LoanAmount: 15000,
                            BalloonPayment: 5000,
                            AnnualMileage: 10000,
                            FinanceCompany: "Financeer",
                            CommencementDate: "2024-01-01",
                            BankDetails: FinanceOptions.BankDetails
                        }
                        dispatch(setObjectState({ root: "FinanceOptions", value }));
                    }}>
                    DEBUG PREMIUM FUNDING CURRENT CUSTOMER
                </button>

                {/* <h1>Current Customer</h1>
                {currentCustomer}
                <h1>Complete Request</h1>
                {complete} */}
            </>
        )
    }
}