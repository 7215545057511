import { useNavigate } from "react-router-dom"
import InputSortCode from "../components/checkout/inputSortCode"
import InputDate from "../components/checkout/inputDate"
import InputDropdown from "../components/checkout/inputDropdown"
import InputNumber from "../components/checkout/inputNumber"
import InputText from "../components/checkout/inputText"
import { ReactComponent as ArrowRight } from '../styles/svg/ArrowRight.svg';

import "../styles/pages/edit_financing.scss";
import { useDispatch, useSelector } from "react-redux"
import { globals } from "../helpers/misc"
import InputCurrency from "../components/checkout/inputCurrency"
import DebuggerButton from "../components/debuggerButton"
import CheckoutRow from "../components/checkout/row"
import CartProducts from "../components/cartContainer"

// For the input of bank details, etc
// The options for each funding source are located in inputDropdown

export default function EditFinancing() {
    const { FinanceOptions, CompleteRequest, PrePurchaseResponse, Cart } = useSelector(state => state.checkout);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let isDisabled = true;

    if (FinanceOptions.FundingSource === "Cash")
        isDisabled = false;

    if (FinanceOptions.FundingSource === "PremiumFunding") {
        isDisabled = Object.entries(FinanceOptions).some(([key, value]) => {
            if (key !== "BankDetails")
                return value === null;
        });
    }

    if (FinanceOptions.FundingSource === "DirectDebit") {
        const isBankDetailsValid = !Object.values(FinanceOptions.BankDetails).some(value => value === null);
        const isCartProductsValid = !PrePurchaseResponse.Products.some(product => product.DirectDebitPeriod === 0);
        isDisabled = !(isBankDetailsValid && isCartProductsValid) || Object.keys(Cart).length === 0;

        console.log(isBankDetailsValid, isCartProductsValid, isDisabled)
    }

    // const errors = generateErrorList("Financing", FinanceOptions);
    const handleSubmit = () => {
        //if (FinanceOptions.FundingSource === "Cash")
        //if (FinanceOptions.FundingSource === "DirectDebit")
        //if (FinanceOptions.FundingSource === "PremiumFunding")

        // TODO
        /*
            CASH is not implemented atm unless they pay via stripe in which case this component won't even be loaded
            Cash will be disabled as an option for now
            Later, if cash is added as a visible option for this page, will need to redirect from here to stripe or alternative cash payment option instead of success page
        */

        navigate(`/payment/response?redirect_status=succeeded`);
    }

    return (
        <>
            {/* <h1 className="h1">Bank Details</h1> */}

            <div className="checkout--container">
                <InputDropdown
                    label={"Funding Source"}
                    identifier={"fundingSource"}
                    initialValue={FinanceOptions.FundingSource}
                    dispatchRoot={"FinanceOptions"}
                    dispatchKey={"FundingSource"}
                    classNameSuffix="EditFinancing"
                />

                {FinanceOptions.FundingSource === "PremiumFunding" && <>
                    {globals.debug && <DebuggerButton type={"premiumfunding"} />}

                    <InputText
                        label={"Finance Company Name"}
                        identifier={"financeCompanyName"}
                        initialValue={FinanceOptions.FinanceCompany}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"FinanceCompany"}
                    />
                    <InputDropdown
                        label={"Finance Type"}
                        identifier={"financeType"}
                        initialValue={FinanceOptions.FinanceType}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"FinanceType"}
                    />
                    <InputDropdown
                        label={"Instalments"}
                        identifier={"instalments"}
                        initialValue={FinanceOptions.Instalments}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"Instalments"}
                    />
                    <InputCurrency
                        label={"Deposit"}
                        identifier={"deposit"}
                        initialValue={FinanceOptions.Deposit}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"Deposit"}
                    />
                    <InputCurrency
                        label={"Finance Payment"}
                        identifier={"Finance Payment"}
                        initialValue={FinanceOptions.FinancePayment}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"FinancePayment"}
                    />
                    <InputCurrency
                        label={"Loan Amount"}
                        identifier={"Loan Amount"}
                        initialValue={FinanceOptions.LoanAmount}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"LoanAmount"}
                    />
                    <InputCurrency
                        label={"Balloon Payment"}
                        identifier={"Balloon Payment"}
                        initialValue={FinanceOptions.BalloonPayment}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"BalloonPayment"}
                    />
                    <InputNumber
                        label={"Mileage Per Annum"}
                        identifier={"mileagePerAnnum"}
                        initialValue={FinanceOptions.AnnualMileage}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"AnnualMileage"}
                        min={1000}
                        max={50000}
                    />

                    <InputDate
                        label={"Loan Start Date"}
                        identifer={"loanStartDate"}
                        initialValue={FinanceOptions.CommencementDate}
                        dispatchRoot={"FinanceOptions"}
                        dispatchKey={"CommencementDate"}
                        additionalClasses={["editFinancingLoanStartDate"]}
                        min={globals.today}
                    />
                </>}

                {FinanceOptions.FundingSource === "DirectDebit" &&
                    <>
                        {globals.debug && <DebuggerButton type={"directdebit"} />}

                        <InputNumber
                            label={"Account Number"}
                            identifier={"accountNumber"}
                            initialValue={FinanceOptions.BankDetails.AccountNumber}
                            dispatchRoot={"FinanceOptions"}
                            dispatchKey={"AccountNumber"}
                            max={99999999}
                        />
                        <InputSortCode initialValue={FinanceOptions.BankDetails.SortCode} />
                        <InputText
                            label={"Bank Name"}
                            identifier={"bankName"}
                            initialValue={FinanceOptions.BankDetails.BankName}
                            dispatchRoot={"FinanceOptions"}
                            dispatchKey={"BankName"}
                        />
                        <InputText
                            label={"Account Holders Name"}
                            identifier={"accountHoldersName"}
                            initialValue={FinanceOptions.BankDetails.AccountHoldersName}
                            dispatchRoot={"FinanceOptions"}
                            dispatchKey={"AccountHoldersName"}
                        />
                        <InputDate
                            label={"Preferred First Payment Date"}
                            identifer={"preferredFirstPaymentDate"}
                            initialValue={FinanceOptions.BankDetails.PreferredFirstPaymentDate}
                            dispatchRoot={"FinanceOptions"}
                            dispatchKey={"PreferredFirstPaymentDate"}
                            classNameSuffix="EditFinancing"
                            min={globals.today}
                        />
                    </>
                }
            </div>

            {FinanceOptions.FundingSource === "DirectDebit" && PrePurchaseResponse.Products.some(product => product.DirectDebitPeriod === 0) && <div className="checkout--container">
                <div style={{ textAlign: "center" }}>
                    <h3>There are products in your cart which cannot be purchased with Direct Debit payments.</h3>
                    <h3>Please either remove them from your cart, or choose another payment method.</h3>
                </div>
                <CartProducts showContainer={false} showTitle={false} />
            </div >}

            {Object.keys(Cart).length === 0 && <CartProducts showContainer={false} showTitle={false} />}

            <button
                style={{ width: "100%" }}
                onClick={handleSubmit}
                className="button button--primary"
                disabled={isDisabled}>
                Continue <ArrowRight />
            </button>
        </>
    )
}