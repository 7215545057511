import { useDispatch, useSelector } from "react-redux";
import Headbar from '../components/headbar';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import { removeProduct } from "../redux/checkout";
import "../styles/components/date.scss";
import { getGapDetailsAsync, storeGapHeldProductAsync } from "../helpers/apiRequestHelper";
import ErrorPage from "./error";
import InputDate from "../components/checkout/inputDate";

const millisecondsInThirtyDays = 2592000000; //60 * 60 * 24 * 30 * 1000;
const millisecondsInThreeDays = 259200000;   // 60 * 60 * 24 * 3 * 1000;
const minDate = (new Date(Date.now() - millisecondsInThirtyDays)).toISOString().slice(0, 10);
const maxDate = (new Date(Date.now() - millisecondsInThreeDays)).toISOString().slice(0, 10);

export default function GapCheck() {
    const { license } = useSelector(state => state.authorise);


    const { Vehicle, GapBlocked, Customer, Cart } = useSelector((state) => state.checkout);

    const [gapDetails, setGapDetails] = useState(null);
    const [error, setError] = useState(null);
    const [hasHeldPurchaseBeenStored, setHasHeldPurchaseBeenStored] = useState(false);
    const [gapProductIdForStoring, setGapProductIdForStoring] = useState(null);
    const [vehiclePurchaseDate, setVehiclePurchaseDate] = useState("yyyy-mm-dd");
    const [isVehiclePurchaseDateRequired, setIsVehiclePurchaseDateRequired] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const basketItemsCount = Object.keys(Cart).length;
    const gapProductId = Object.values(Cart).find(product => product.name === "GAP Insurance")?.id || undefined;

    const removeGapFromBasket = () => {
        if (gapProductId !== undefined)
            dispatch(removeProduct(gapProductId));
    }

    let additionalMessage;
    const updateAdditionalMessage = () => {
        if (basketItemsCount === 0) {
            additionalMessage = <span>Your basket is now empty. Please return to the menu to add another product.</span>
        } else if (basketItemsCount >= 1) {
            additionalMessage = <span>You may continue to pay for your remaining purchases.</span>
        } else {
            additionalMessage = "";
        }
    }

    useEffect(() => {
        if (gapProductId !== undefined)
            setGapProductIdForStoring(gapProductId);
    }, []);

    useEffect(() => {
        if (GapBlocked === false) {
            navigate("/payment");
            return;
        }

        if (license && !error && !gapDetails) {
            getGapDetailsAsync(Vehicle.RegistrationNumber, Customer.MobileTelephone, Customer.EmailAddress, license)
                .then(response => setGapDetails(response))
                .catch(error => setError(error.message));
        }

        if (license && !error && gapDetails) {
            //switch (5) { //for testing
            switch (gapDetails.Status) {
                case 0:
                    //TooSoon - error
                    if (hasHeldPurchaseBeenStored)
                        break;

                    if (gapProductId !== undefined) {
                        removeGapFromBasket();
                        updateAdditionalMessage();
                        setMessage(
                            <>
                                <span>You may not purchase GAP Insurance on the same day as your vehicle.</span>
                                <span>GAP Insurance has been removed from your basket.</span>
                                {additionalMessage}
                            </>
                        );
                    }

                    storeGapHeldProductAsync(gapProductIdForStoring, Customer.EmailAddress, null, license).then(resolve => setHasHeldPurchaseBeenStored(true)).catch(error => setError(error.message));
                    break;
                case 1:
                    //InsideRule -- show opt in message
                    removeGapFromBasket();
                    updateAdditionalMessage();
                    setMessage(
                        <>
                            <span>You are able to opt-in to GAP Insurance.</span>
                            <span>To opt-in, please visit acl.co.uk, and then return to this page.</span>
                            <span>For now, GAP Insurance has been removed from your basket.</span>
                            {additionalMessage}
                        </>
                    );
                    break;
                case 2:
                    //OutsideRule -- can go straight to payment
                    navigate("/payment");
                    break;
                case 3:
                    //EnquiryNotFound -- date input vehicle purchase date
                    if (hasHeldPurchaseBeenStored)
                        break;

                    setIsVehiclePurchaseDateRequired(true);
                    setMessage(
                        <>
                            <span>Enquiry Not Found</span>
                            <span>Please input the date you purchased your vehicle in order to proceed.</span>
                            <span>Cannot be before the last thirty days, nor within the past three days.</span>
                            <span>In the case that the vehicle purchase date falls outside of this range, you will be ineligible for purchasing GAP Insurance.</span>

                            <div className="DateSelector--gapCheck" style={{ width: "100%" }}>
                                <input type="date"
                                    onChange={(e) => setVehiclePurchaseDate(e.target.value)}
                                    min={minDate}
                                    max={maxDate}
                                    onKeyDown={(e) => e.preventDefault()}
                                ></input >
                            </div>
                        </>
                    );

                    break;
                case 4:
                    //OptedInManually -- can go straight to payment
                    navigate("/payment");
                    break;
                case 5:
                    //StagesNotComplete -- error
                    removeGapFromBasket();
                    updateAdditionalMessage();

                    setMessage(
                        <>
                            <span>Some stages have not been completed.</span>
                            <span>You are currently not able to purchase GAP Insurance and it has been removed from your basket.</span>
                            {additionalMessage}
                        </>
                    );
                    break;
                default:
                    setMessage(<Loading />);
                    break;
            }
        }
    }, [gapDetails, license]);

    if (error) return <ErrorPage message={error || "Unauthorised."} />

    return (
        <>
            <Headbar back={"/checkout"} backText={"Back To Basket"} />

            <div className="checkout--container" style={{ display: "flex", alignItems: "center" }}>
                <span>GAP Insurance is subject to additional requirements before being eligible for purchase.</span>
            </div>

            <div className="checkout--container" style={{ display: "flex", alignItems: "center" }}>
                {message === "" ? <Loading /> : message}
            </div>

            <div className="button-wrap">
                <button
                    style={{ width: "100%" }}
                    className="button button--primary"
                    onClick={
                        basketItemsCount === 0 ?
                            () => navigate("/")
                            :
                            () => {
                                if (!isVehiclePurchaseDateRequired || (isVehiclePurchaseDateRequired && vehiclePurchaseDate !== "yyyy-mm-dd")) {
                                    storeGapHeldProductAsync(gapProductIdForStoring, Customer.EmailAddress, isVehiclePurchaseDateRequired ? vehiclePurchaseDate : null, license)
                                        .then(resolve => {
                                            setHasHeldPurchaseBeenStored(true);
                                            navigate("/payment");
                                        })
                                        .catch(error => setError(error.message));
                                }
                            }}
                    disabled={isVehiclePurchaseDateRequired && vehiclePurchaseDate === "yyyy-mm-dd"}
                >

                    <span className="label">{basketItemsCount === 0 ? "Return to Menu" : "Continue to Payment"}</span>
                    <ArrowRight />
                </button>
            </div >
        </>
    )
}