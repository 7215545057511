import { useState } from "react"
import { useDispatch } from "react-redux";
import { setState } from "../../redux/checkout";
import { globals } from "../../helpers/misc";

export default function InputCheckbox(
    {
        label,
        identifier,
        isChecked,
        updaterFn,
        dispatchRoot,
        dispatchKey
    }
) {

    const dispatch = useDispatch();

    const handleChange = () => {
        if (isChecked) {
            // No longer on finance, dispatch CASH
            globals.debug && console.log(`${dispatchRoot}: ${dispatchKey} dispatching ${typeof dispatchKey}: CASH`);
            dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: "CASH" }));
        } else {
            // Now finance, dispatch FINANCE
            globals.debug && console.log(`${dispatchRoot}: ${dispatchKey} dispatching ${typeof dispatchKey}: FINANCE`);
            dispatch(setState({ root: dispatchRoot, key: dispatchKey, value: "FINANCE" }));
        }

        updaterFn(!isChecked);
    }

    return (
        <>
            <div className="input--checkbox">
                <div>
                    <label>{label}</label>
                </div>

                <div>
                    <input
                        name={`vehicleIsFinancePurchaseCheckbox}`}
                        id={`vehicleIsFinancePurchaseCheckbox`}
                        type="checkbox"
                        onChange={handleChange}
                        checked={isChecked}
                    />
                </div>

            </div>
        </>
    )
}